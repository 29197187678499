import React, { useEffect, useState, useRef, useContext } from 'react';
import { IonPage, IonContent, IonButton, IonInput, IonLabel, IonRouterOutlet, IonSlide, IonSlides, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect, useHistory, useParams, RouteComponentProps } from 'react-router-dom';

import Step1Form from '../components/formComponent/Step1Form';
import Step2Form from '../components/formComponent/Step2Form';
import Step3Form from '../components/formComponent/Step3Form';
import Step4Form from '../components/formComponent/Step4Form';
import Step5Form from '../components/formComponent/Step5Form';
import Step6Form from '../components/formComponent/Step6Form';
import Step7Form from '../components/formComponent/Step7Form';
import { getClaimant } from '../services/api';
import { LanguageContext } from '../services/LanguageContext';
import Step8Form from '../components/formComponent/Step8Form';
import Step9Form from '../components/formComponent/Step9Form';
import Step10Form from '../components/formComponent/Step10Form';



type TazResult = {
  response: string;
  // Include other properties from the tazRes object as needed
};

type CheckResult = {
  response: string;
};

const NewClaim: React.FC<RouteComponentProps<{ step: string }>> = ({ match }) => {
  const history = useHistory();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  //   const [step, setStep]  = useState('1'); 
  const { step } = match.params;
  // Convert the step value to a number
  const currentStep = parseInt(step, 10);
  const { languageData } = useContext(LanguageContext);


  const [tazResult, setTazResult] = useState<TazResult | null>(null);
  const [checkResult, setCheckResult] = useState<CheckResult | null>(null);
  const [check2Result, setCheck2Result] = useState<CheckResult | null>(null);

  const handleTazResult = (result: TazResult) => {
    setTazResult(result);
    handleNextStep();
    // You can perform any necessary actions with the result here
  };
  const handleCheckResult = (result: CheckResult) => {
    setCheckResult(result);
    handleNextStep();
    // You can perform any necessary actions with the result here
  };
  const handleCheck2Result = (result: CheckResult) => {
    setCheck2Result(result);
    handleNextStep();
    // You can perform any necessary actions with the result here
  };
  // Array of form components for each step
  const formComponents = [
    <Step1Form onTazResult={handleTazResult} />,
    <Step2Form onClick={() => { handleNextStep() }} tazResult={tazResult} />,
    <Step3Form onCheckResult={handleCheckResult} />,
    <Step4Form onClick={() => { handleNextStep() }} checkResult={checkResult} />,
    <Step5Form onCheckResult={handleCheck2Result} />,
    <Step6Form onClick={() => { handleNextStep() }} checkResult={check2Result} />,
    <Step7Form onClick={() => { handleNextStep() }} />,
    <Step8Form onClick={() => { handleNextStep() }} />,
    <Step9Form onClick={() => { handleNextStep() }} />,
    <Step10Form onClick={() => { handleNextStep() }} />,

  ];

  const totalSteps = formComponents.length; // Total number of steps in the form



  const handleNextStep = () => {
    // Proceed to the next step
    const nextStep = currentStep;
    if (!isNaN(nextStep) && slidesRef.current) {
      slidesRef.current.slideTo(nextStep);
    }
  };
  const handlePrevStep = () => {
    // Proceed to the previous step
    const prevStep = currentStep - 2;

    if (!isNaN(prevStep) && slidesRef.current) {
      slidesRef.current.slideTo(prevStep);
    }
  };

  useEffect(() => {

    const stepIndex = parseInt(step, 10);

    if (!isNaN(stepIndex) && slidesRef.current) {
      slidesRef.current.slideTo(stepIndex - 1);
    }

  }, [step]);
  const fetchClaimant = async () => {
    const id_number = localStorage.getItem('idNumber');
    if (id_number)
      await getClaimant(id_number).then((response) => {
        console.log('response', response.claimant_details.account_details)
        localStorage.setItem('tazValues', JSON.stringify(response.claimant_details.personal_details))
        //todo remove storage when get back image for back end storage
        localStorage.setItem('Taz', response.claimant_details.personal_details.id_picture)
        localStorage.setItem('CheckClaimant', response.claimant_details.account_details.bank_account_picture)
        localStorage.setItem('checkClaimantValues', JSON.stringify(response.claimant_details.account_details))
        localStorage.setItem('id_picture_guid', response.claimant_details.personal_details.id_picture_guid)
        localStorage.setItem('bank_account_picture_guid', response.claimant_details.account_details.bank_account_picture_guid)
        // localStorage.setItem('checkClaimantValues', JSON.stringify(response.claimant_details.account_details))

        if (slidesRef.current) {
          if (response.claimant_details.personal_details.id_picture !== '' && response.claimant_details.account_details.bank_account_picture !== '') {
            slidesRef.current.slideTo(4);
          } else if (response.claimant_details.personal_details.id_picture !== '' && response.claimant_details.account_details.bank_account_picture === '') {
            console.log('first')
            slidesRef.current.slideTo(2);
          } else if (response.claimant_details.personal_details.id_picture !== '') {
            slidesRef.current.slideTo(1);
          } else {
            slidesRef.current.slideTo(0);
          }
        }
      });
  };
  useEffect(() => {

    if (!step) {
      console.log('clear storage')
      localStorage.removeItem("CheckDebtor");
      localStorage.removeItem("checkDebtorValues");
      localStorage.removeItem("claim_number");

      history.push(`/new-claim/1`);
    } else {
      fetchClaimant();
    }

  }, []);

  const handleSlideDidChange = async () => {
    const activeIndex = await slidesRef.current?.getActiveIndex();
    if (activeIndex !== undefined) {
      history.push(`/new-claim/${activeIndex + 1}`);
    }
  };


  const slideOpts = {
    initialSlide: parseInt(step, 10) - 1,
    speed: 400,
    effect: 'slide',
    navigation: true,
    allowTouchMove: false

  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{languageData.newClaim}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonReactRouter>
          <IonRouterOutlet>

            <Route
              path="/new-claim/:step"
              render={() => (
                <IonSlides
                  ref={slidesRef}
                  key={currentStep}
                  onIonSlideDidChange={handleSlideDidChange}
                  options={slideOpts}>
                  {formComponents.map((formComponent, index) => (
                    <IonSlide key={index} className='ion-padding'>{formComponent}</IonSlide>
                  ))}

                </IonSlides>
              )}
            />
          </IonRouterOutlet>
        </IonReactRouter>


      </IonContent>
      <div className="buttonWrapper">
        {currentStep > 1 && (
          <IonButton id="prevBtn" className='prevBtn' fill="outline" onClick={handlePrevStep}>
            {languageData.Prev}
          </IonButton>

        )}
        {currentStep < totalSteps && (
          <IonButton id="nextBtn" fill="clear" className='nextBtn' onClick={handleNextStep}>


          </IonButton>

        )}

      </div>
    </IonPage>
  );
};

export default NewClaim;
