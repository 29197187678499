import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonDatetime, IonDatetimeButton, IonModal, IonToast, IonHeader, IonButtons, IonContent, IonText, IonTitle, IonToolbar, IonRadio, IonRadioGroup, IonCheckbox } from '@ionic/react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { getGeolocation, updateClaim } from '../../services/api';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { LanguageContext } from '../../services/LanguageContext';
import { stringify } from 'querystring';
// import '../../pages/Page.css';
interface CheckDebtorValues {
    first_name?: string;
    last_name?: string;
    id_number?: string;
    email?: string;
    phone_number?: string;
    city?: string;
    street?: string;
    address?: string;
    house_number?: string;
    zip?: string;
    check_number?: number;
    date?: string;
    amount?: number;
    bank_id?: number;
    bank_name?: string;
    branch?: number;
    account_number?: number;
    bank_account_picture?: string;
    bank_account_picture_guid?: string;

}
interface ClaimValues {
    id_number?: string,
    claim_details: {
        claim_date?: string;
        claim_number?: string;
        claim_type?: string;
        status?: string;
        debtor_details?: {
            first_name?: string;
            last_name?: string;
            id_number?: string;
            email?: string;
            phone_number?: string;
            city?: string;
            street?: string;
            house_number?: string;
            zip?: string;

        },
        debts?: [
            {
                check_number?: number;
                amount?: string;
                date?: string;
                bank_name?: string;
                bank_id?: number;
                branch?: number;
                account_number?: number;
                bank_account_picture?: string;
                bank_account_picture_guid?: string;

            }
        ]
    }
}
type Props = {
    onClick: any;
};

const Step7Form: React.FC<Props> = ({ onClick }): JSX.Element => {
    const { languageData } = useContext(LanguageContext);

    const modal = useRef<HTMLIonModalElement>(null);
    let history = useHistory();


    const close = () => {
        modal.current?.dismiss();

    }
    const confirm = () => {
        const termsAgreement = document.getElementById('termsAgreement') as HTMLInputElement;

        if (!termsAgreement?.checked) {
            setTermsError(true);
            return;
        }
        setTermsError(false);

        const form = formRef.current;
        close();
        handleSubmit(form, 'sendClaim')
    }


    const [termsError, setTermsError] = useState(false);

    const [claimOption, setClaimOption] = useState('option1')

    const [current_claim, setCurrentClaim] = useState<ClaimValues>();
    const [newAmount, setnewAmount] = useState<string>('');
    const [ribitOption, setRibitOption] = useState('Ribit1')
    const [MadadOption, setMadadOption] = useState('Madad1')

    const [error, setError] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [isOpen, setIsOpen] = useState(false);

    const initialCheckValues: CheckDebtorValues = {
        first_name: '',
        last_name: '',
        id_number: '',
        email: '',
        phone_number: '',
        city: '',
        street: '',
        house_number: '',
        zip: '',
        check_number: undefined,
        date: '',
        amount: undefined,
        bank_id: undefined,
        bank_name: '',
        branch: undefined,
        account_number: undefined,
        bank_account_picture: '',
    };
    const [checkDebtorValues, setCheckDebtorValues] = useState<CheckDebtorValues>(() => {
        const storedCheckValues = localStorage.getItem('checkDebtorValues');
        return storedCheckValues ? JSON.parse(storedCheckValues) : initialCheckValues;
    });




    useEffect(() => {
        console.log('checkDebtorValues', checkDebtorValues)
        const claim_values = localStorage.getItem('current_claim');
        if (claim_values) {

            console.log(newAmount)
            setCurrentClaim(JSON.parse(claim_values));
            calculateNewAmount(JSON.parse(claim_values));
            // if (current_claim?.claim_details.debts) {
            //     setnewAmount(current_claim?.claim_details.debts[0].amount);
            // }
        }
    }, []);

    const calculateNewAmount = (claim: ClaimValues) => {
        const debt = claim?.claim_details.debts;
        console.log("debt", debt)
        if (debt && debt[0].amount) {
            // console.log(debt[0].amount)
            const n = Number(debt[0].amount)
            const x = (n * 2.5 / 100) + (n * 2 / 100) + n;
            setnewAmount(x.toString());
        }



        // return '123456';
        // const n_amount = '4';
        // setnewAmount(n_amount)
    }

    // useEffect(() => {
    //     console.log('checkDebtorValues', checkDebtorValues)
    //     localStorage.setItem('checkDebtorValues', JSON.stringify(checkDebtorValues));
    // }, [checkDebtorValues]);

    // const handleChange = (e: any) => {
    //     const f = calculateNewAmount();
    //     console.log(f)
    //     console.log("e.target", e.target)

    //     const { name, value } = e.target;
    //     // setCheckDebtorValues((prevValues) => ({
    //     //     ...prevValues,
    //     //     [name]: value,
    //     // }));
    // };

    const handleSubmit = async (data: any, btn?: string) => {
        console.log("data", data);
        console.log("checkDebtorValues", checkDebtorValues);
        //todo set the API request
        setIsOpen(true)
        setResponseMessage(languageData.saveDataMessageSuccess)

        onClick();
        // await saveFormData(checkDebtorValues, btn); // Call the API to save the form data

    };
    const getCurrentDate = (separator = '') => {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
    // const saveFormData = async (formData: CheckDebtorValues, btn?: string) => {

    //     const claimant_id_number = localStorage.getItem('idNumber') || '';
    //     const claim_number = localStorage.getItem('claim_number') || '';
    //     const CheckDebtor = localStorage.getItem('CheckDebtor') || '';
    //     console.log('formData', formData)
    //     const body: ClaimValues = {
    //         "id_number": claimant_id_number,
    //         "claim_details": {
    //             "claim_date": getCurrentDate("-"),
    //             "claim_type": claimOption,
    //             "debtor_details": {
    //                 "first_name": formData.first_name,
    //                 "last_name": formData.last_name,
    //                 "id_number": formData.id_number,
    //                 "email": formData.email,
    //                 "phone_number": formData.phone_number,
    //                 "city": formData.city,
    //                 "street": formData.street,
    //                 "house_number": formData.house_number,
    //                 "zip": formData.zip,

    //             },
    //             "debts": [
    //                 {
    //                     "check_number": formData.check_number,
    //                     "amount": formData.amount,
    //                     "date": formData.date,
    //                     "bank_name": formData.bank_name,
    //                     "bank_id": formData.bank_id,
    //                     "branch": formData.branch,
    //                     "account_number": formData.account_number,
    //                     "bank_account_picture_guid": formData.bank_account_picture_guid,
    //                 }
    //             ]
    //         },
    //     };
    //     if (btn === 'sendClaim') {
    //         body.claim_details.status = 'in_progress';

    //     }
    //     if (claim_number) {
    //         body.claim_details.claim_number = claim_number
    //     }
    //     const updateRes: any = await updateClaim(body).then((res) => {
    //         console.log(res);
    //         console.log('btn', btn);
    //         switch (res.status) {
    //             case 404:
    //                 setError(true);
    //                 setResponseMessage(languageData.saveDataMessageError);
    //                 setIsOpen(true);
    //                 break;
    //             case 200:
    //                 setResponseMessage(languageData.saveDataMessageSuccess)
    //                 setIsOpen(true);
    //                 localStorage.setItem('claim_number', res.data.claim_number)
    //                 if (btn === 'sendClaim') {
    //                     close();
    //                     localStorage.removeItem("CheckDebtor");
    //                     localStorage.removeItem("checkDebtorValues");
    //                     localStorage.removeItem("claim_number");
    //                     const timeout = setTimeout(() => {
    //                         // 👇️ redirects to an external URL
    //                         window.location.replace(`/thank-you/${claimOption}`);

    //                         // history.push(`/thank-you/${claimOption}`);
    //                         // window.location.replace(`${domain}/logout`);
    //                     }, 500);
    //                     return () => clearTimeout(timeout);
    //                 }

    //                 onClick();
    //                 break;
    //             default:
    //                 setError(true);
    //                 setResponseMessage(languageData.saveDataMessageError);
    //                 setIsOpen(true);
    //                 break;
    //         }
    //     });
    // };

    const formRef = useRef<HTMLFormElement>(null);


    const radioGroupChange = (event: any) => {
        console.log('event', event.detail.value)
        setClaimOption(event.detail.value);
    }
    const radioRibitChange = (event: any) => {
        console.log('event', event.detail.value)
        setRibitOption(event.detail.value);
        if (current_claim)
            calculateNewAmount(current_claim)
    }
    const radioMadadChange = (event: any) => {
        console.log('event', event.detail.value)
        setMadadOption(event.detail.value);
    }

    return (
        <div className="container">
            <div>
                <h2>{languageData.Step_7}</h2>
                <h3>{languageData.Step_7_intro}</h3>
                <form id="formh" ref={formRef}>

                    <IonRadioGroup value={ribitOption} onIonChange={(e) => { radioRibitChange(e) }} >
                        <IonItem >
                            <IonRadio value="Ribit1" ></IonRadio>
                            <IonLabel className="ion-text-wrap"> {languageData.Ribit1}</IonLabel>
                        </IonItem>
                        {ribitOption === "Ribit1" && (
                            <p className="bigAmount">
                                {newAmount}₪
                            </p>
                        )}
                        <IonItem>
                            <IonRadio value="Ribit2" ></IonRadio>
                            <IonLabel className="ion-text-wrap"> {languageData.Ribit2}</IonLabel>



                        </IonItem>
                        {ribitOption === "Ribit2" && (
                            <div className="Ribit2Option">


                                <IonRadioGroup value={MadadOption} onIonChange={(e) => { radioMadadChange(e) }} >
                                    <IonItem >
                                        <IonRadio value="Madad1" ></IonRadio>
                                        <IonLabel className="ion-text-wrap"> {languageData.Lawn}</IonLabel>
                                    </IonItem>

                                    <IonItem>
                                        <IonRadio value="Madad2" ></IonRadio>
                                        <IonLabel className="ion-text-wrap"> {languageData.MadadPlus}</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonRadio value="Madad3" ></IonRadio>
                                        <IonLabel className="ion-text-wrap">{languageData.MadadBuild}</IonLabel>
                                    </IonItem>

                                </IonRadioGroup>
                            </div>
                            // {ribitOption === "Ribit4" && (

                            //     <IonItem fill="outline">
                            //         <IonInput
                            //             type="text"
                            //             name="other"

                            //         ></IonInput>
                            //     </IonItem>


                            // )}
                        )}
                        <IonItem>
                            <IonRadio value="Ribit3" ></IonRadio>
                            <IonLabel className="ion-text-wrap">{languageData.Ribit3}</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="Ribit4" ></IonRadio>
                            <IonLabel className="ion-text-wrap">{languageData.other}</IonLabel>

                        </IonItem>
                    </IonRadioGroup>
                    {ribitOption === "Ribit4" && (
                        <div className="otherWrappaer">

                            <IonItem fill="outline">
                                <IonInput
                                    type="text"
                                    name="other"

                                ></IonInput>
                            </IonItem>
                        </div>


                    )}

                </form>
                <IonButton onClick={(e) => { handleSubmit(e) }}>
                    {languageData.Submit}
                </IonButton>

                <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />

            </div >
        </div >
    );
};

export default Step7Form;
