import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { addOutline,logOutOutline,cameraOutline,archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, speedometerOutline, personOutline } from 'ionicons/icons';
import './Menu.css';
import { useContext, useState } from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { LanguageContext } from '../services/LanguageContext';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}






const Menu: React.FC = () => {
 
  const location = useLocation();
  const language = localStorage.getItem('tr') || '';
  const { languageData } = useContext(LanguageContext);
  // console.log('languageData',languageData);
  const appPages: AppPage[] = [
    {
      title: languageData.Dashboard,
      url: '/',
      iosIcon: speedometerOutline,
      mdIcon: speedometerOutline
    },
    {
      title: languageData.profile,
      url: '/profile',
      iosIcon: personOutline,
      mdIcon: personOutline
    },
    {
      title:languageData.newClaim,
      url: '/new-claim/',
      iosIcon: addOutline,
      mdIcon: addOutline
    },
    {
      title: languageData.logout,
      url: '/logout',
      iosIcon: logOutOutline,
      mdIcon: logOutOutline,
      
    },
    
  ];

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent >
        <IonList id="inbox-list">
          
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
         <LanguageSwitcher/>
        </IonList>

        
      </IonContent>
    </IonMenu>
  );
};

export default Menu;


