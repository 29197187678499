import axios from 'axios';


// const localdata = localStorage.getItem("user") as string ;
// const localuser = JSON.parse(localdata);


// const API= process.env.REACT_APP_API_URL;
const API = "https://qb728gcy60.execute-api.eu-west-2.amazonaws.com/default/";
// const API= "https://5x57nzrigwkkzf23l2psv7r7qi0gqtcp.lambda-url.eu-west-2.on.aws/";
// const APIKEY = process.env.REACT_APP_API_KEY ;


const lawkal = axios.create({
    baseURL: API,
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers':'Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers'
        // 'Authorization': getAuthorizationHeader()
    },
});
lawkal.interceptors.request.use(function (config) {
    let token = localStorage.getItem('access_token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
lawkal.interceptors.response.use((res) => {

    return res;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    if (error.response) {
        return error.response;
    }


});

export function registerUser(id_number: string, phone_number: string) {
    console.log('id', id_number);
    console.log('phone_number', phone_number);
    // const res = {"response": "OK", "id_number": "308880434", "otp": "497302"};
    // return res;

    return lawkal.post('register', {
        "phone_number": phone_number,
        "id_number": id_number,
        "message": "This is your one time pwd for Lawkal service:",
        "digits": "6"
    });


}
export function sendOtp(id_number: string, phone_number: string) {
    console.log('id', id_number);
    console.log('phone_number', phone_number);
    // const res = {"response": "user_not_found", "id_number": "308880434", "otp": "497302"};
    // return res;

    return lawkal.post('login', {
        "phone_number": phone_number,
        "id_number": id_number,
        "message": "This is your one time pwd for Lawkal service:",
        "digits": "6"
    });


}
export function singleOtp(phone_number: string, id_number: string) {
    // const res = {"response": "OK","id_number":308880434, "otp": "497302"};
    // return res;

    return lawkal.post('singleOTP', {
        "phone_number": phone_number,
        "id_number": id_number,
        "message": "This is your one time password for Lawkal service:",
        "digits": "6"
    });


}

export function validateOtp(otpCode: string, idNumber: string, phone_number: string) {
    // console.log('otpCode',otpCode);
    //   const res = {"response": "OK", "id_number": "123456789", "valid_otp": true};
    //   return res;
    console.log(otpCode, idNumber, phone_number)
    return lawkal.post('validateOTP',
        {
            "phone_number": phone_number,
            "id_number": idNumber,
            "otp": otpCode
        });


}
export function getClaims(id_number: string) {

    // return  lawkal.get('getClaims?IdNumber=123456789').then( x=> x.data ); 
    return lawkal.get('getClaims?IdNumber=' + id_number);

    const data: any = {};
    return data;

}
export function updateClaim(claim: any) {
    // console.log("claim", claim)

    return lawkal.post('updateClaim', claim);

    // const res = {
    //     "claim_number": "a5f2af1b-9af3-446e-99fd-03d731d3efd9"
    //   }
    //   return res;
}

export function sendTaz(img: string): Promise<any> {
    console.log('img', img);
    const body = {
        "img_data": img
    }
    return lawkal.post('uploadIDCard', body);

    // const res = {
    //   "response": "OK",
    //   "first_name": "moshe",
    //   "last_name": "cohen",
    //   "id_number": "308880160",
    // }
    // return res;




}
export function sendCheck(img: string): Promise<any> {
    // console.log('img',img);
    const body = {
        "img_data": img
    }
    return lawkal.post('uploadCheck', body);

    // const res = {
    //     "statusText": "OK",
    //     "data": {
    //         "account_number": "",
    //         "address": [],
    //         "bank_city": "נתניה",
    //         "bank_id": "10",
    //         "bank_name": "בנק גכג הפועלים בע\"מ",
    //         "branch": "749",
    //         "branch_address": "ככר  גג העצמאות 11 ",
    //         "branch_name": "הים",
    //         "check_number": "",
    //         "email": "",
    //         "first_name": [
    //             "בן",
    //             "הדס",
    //             "דניה",
    //             "תו",
    //             "עמי"
    //         ],
    //         "id_number": [],
    //         "last_name": [],
    //         "phone_number": []
    //     }
    // } as any;
    // return Promise.resolve(res);




}
export function getClaimant(id_number: string) {

    return lawkal.get('getClaimant?IdNumber=' + id_number).then(x => x.data);

    // const UserProfile = {
    //     "fullName": 'Moshe Cohen',
    //     "nickName": 'Moshe Cohen HAMELECH',  
    //     "email": 'moshe@gmail.com',
    //     "phone": '+972546499444'
    //   } as any;

    // return UserProfile;

}

export function updateClaimant(profile: any) {
    // console.log("profile", profile)
    return lawkal.post('updateClaimant', profile);
}



export function deleteUser() {
    const res = { "dfsd": "dfsd" }
    return res;
    return lawkal.delete('remove-account').then(x => x.data);

}

export function getClaim(claim_number: string) {
    console.log('claim_number', claim_number);
    // const res ={

    //     "claimant_details": {
    //         "personal_details": {
    //             "first_name": "moshe",
    //             "last_name": "cohen",
    //             "id_number": "308880160",
    //             "phone_number": "+9725445544226",
    //             "email": "mail@gmail.com",
    //             "city": "netanya",
    //             "street": "jjj",
    //             "house_number": "5",
    //             "zip": "123456",
    //             "id_picture": ""
    //         },
    //         "account_details": {
    //             "bank_name": "poalim",
    //             "bank_id": "12",
    //             "branch_name": "hayam",
    //             "branch": "749",
    //             "account_number": "1234",
    //             "bank_account_picture": ""
    //         }
    //     },
    //     "claim_number": "3ab9ba0e-7be0-4c3a-a22c-7afd0af4e7f3",
    //     "status": "new",
    //     "claim_date": "2023-07-12",
    //     "debtor_details": {
    //       "first_name": "sadfsd",
    //       "last_name": "sdafas",
    //       "id_number": "308880434",
    //       "phone_number": "+972508987676",
    //       "email": "email@df.com",
    //       "city": "Tel Aviv",
    //       "street": "gdfg",
    //       "house_number": "34",
    //       "zip": "435345",
    //       "id_picture": ""
    //     },
    //     "debts": [
    //       {
    //         "check_number": "12324567",
    //         "amount": "12425",
    //         "date": "2023-07-20",
    //         "bank_name": "sdfgsdf",
    //         "bank_id": "12",
    //         "branch": "346",
    //         "account_number": "8765432",
    //         "bank_account_picture": "",
    //         "payment_auth_number":"1234567890"
    //       }
    //     ]

    // }as any
    //   return res;
    return lawkal.get('getClaim?claimNumber=' + claim_number);
}




const endpoint = axios.create({
    baseURL: 'https://wtfismyip.com/',
    headers: {
        'Content-Type': 'application/json'

    },
});
endpoint.interceptors.response.use((response) => {
    if (response.status === 200) {
        // console.log(response.data);
        return response.data;
    }
});



export async function getGeolocation(): Promise<any> {

    try {
        const response = await axios.get('https://wtfismyip.com/json');
        // console.log(response);
        return response.data;
    } catch (error) {
        // console.error(error);
        return error;
    }



}



const validateToken = () => {
    const expiration_token = localStorage.getItem('expiration_token');
    if (expiration_token) {
        let expiration = parseFloat(expiration_token);
        let now = Math.floor(Date.now() / 1000);
        if (now > expiration) {
            return false;
        } else {

            return true;
        }
    } else {
        return false;
    }


}

