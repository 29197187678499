import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonToast } from '@ionic/react';

import { updateClaimant } from '../../services/api';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../../services/LanguageContext';

interface CheckClaimantValues {
  bank_id?: number;
  bank_name?: string;
  branch?: number;
  account_number?: number;
  bank_account_picture?: string;
  bank_account_picture_guid?: string;
}

interface personalDetails {

  first_name?: string;
  last_name?: string;
  id_number?: number;
  phone_number?: string;
  email?: string;
  city?: string;
  house_number?: number;

  street?: string;
  zip?: number;
  id_picture?: string;
  bank_account_picture_guid?: string;
}

type Props = {
  onClick: any;
  checkResult: any;
  type?: string
};

const Step4Form: React.FC<Props> = ({ onClick, checkResult, type }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { languageData } = useContext(LanguageContext);

  const initialCheckValues: CheckClaimantValues = {
    bank_id: undefined,
    bank_name: '',
    branch: undefined,
    account_number: undefined,
  };
  const [error, setError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  // const [checkClaimantValues, setCheckClaimantValues] = useState<CheckClaimantValues>(checkResult)
  const [checkClaimantValues, setCheckClaimantValues] = useState<CheckClaimantValues>(() => {
    const storedCheckValues = localStorage.getItem('checkClaimantValues');
    return storedCheckValues ? JSON.parse(storedCheckValues) : initialCheckValues;
  });

  const [validity, setValidity] = useState({
    bank_id: true,
    bank_name: true,
    branch: true,
    account_number: true,
  });

  useEffect(() => {
    localStorage.setItem('checkClaimantValues', JSON.stringify(checkClaimantValues));
  }, [checkClaimantValues]);



  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCheckClaimantValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await saveFormData(checkClaimantValues); // Call the API to save the form data

      } catch (error) {
        console.log('API request error:', error);
        // Handle the error here
      }
    }
  };

  const saveFormData = async (formData: CheckClaimantValues) => {

    const claim_number = localStorage.getItem('claim_number');
    const id_number = localStorage.getItem('idNumber');
    console.log(id_number)
    let checkValues = localStorage.getItem('checkClaimantValues')
    let checkClaimentValues: CheckClaimantValues = {}
    if (checkValues) {
      checkClaimentValues = JSON.parse(checkValues)
    }
    let tazValues = localStorage.getItem('tazValues')
    let personal_details: personalDetails = {}
    if (tazValues) {
      personal_details = JSON.parse(tazValues)
      personal_details.id_picture = '';
    }

    const body = {
      "claimant_details": {
        "personal_details": personal_details,
        "account_details": {
          "bank_name": formData.bank_name,
          "bank_id": formData.bank_id,
          "branch": formData.branch,
          "account_number": formData.account_number,
          // "bank_account_picture":checkClaimentValues?.bank_account_picture,
          "bank_account_picture_guid": formData.bank_account_picture_guid
        }
      }

    }

    const updateRes: any = await updateClaimant(body).then((res) => {
      console.log(res.status)
      switch (res.status) {
        case 404:
          setError(true)
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage(languageData.saveDataMessageSuccess)
          setIsOpen(true);
          onClick();

          break;
        default:
          setError(true)
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
      }

    });

  };

  const validateForm = () => {
    const { bank_id, bank_name, branch, account_number } = checkClaimantValues;
    const chBankValid = bank_name?.trim() !== '';

    let chNumberValid = false;
    if (typeof bank_id === "string") { // we only process strings!  
      chNumberValid = !isNaN(bank_id) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(bank_id)) // ...and ensure strings of whitespace fail
    }

    let chBankBranchValid = false;
    if (typeof branch === "string") { // we only process strings!  
      chBankBranchValid = !isNaN(branch) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(branch)) // ...and ensure strings of whitespace fail
    }
    let chBankAccountValid = false;
    if (typeof account_number === "string") { // we only process strings!  
      chBankAccountValid = !isNaN(account_number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(account_number)) // ...and ensure strings of whitespace fail
    }
    setValidity({
      bank_id: chNumberValid,
      bank_name: chBankValid,
      branch: chBankBranchValid,
      account_number: chBankAccountValid,
    });

    return (
      chNumberValid &&
      chBankValid &&
      chBankBranchValid &&
      chBankAccountValid
    );
  };
  // useEffect(() => {
  //   if(checkResult)
  //   setCheckClaimantValues(checkResult.account_details);
  // }, [checkResult]);
  return (
    <div className="container">
      <div>
        <h2>{type ? languageData.Step_6 : languageData.Step_4}</h2>
        <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel position="floating">{languageData.Bank_Number}:</IonLabel>
            <IonInput
              type="number"
              name="bank_id"
              value={checkClaimantValues.bank_id}
              onIonChange={handleChange}
              className={!validity.bank_id ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.bank_id && <p><IonLabel color="danger">{languageData.valid_check_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Bank_Name}:</IonLabel>
            <IonInput
              type="text"
              name="bank_name"
              value={checkClaimantValues.bank_name}
              onIonChange={handleChange}
              className={!validity.bank_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.bank_name && <p><IonLabel color="danger">{languageData.valid_bank}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Branch_Number}:</IonLabel>
            <IonInput
              type="number"
              name="branch"
              value={checkClaimantValues.branch}
              onIonChange={handleChange}
              className={!validity.branch ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.branch && <p><IonLabel color="danger">{languageData.valid_branch}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Account_Number}:</IonLabel>
            <IonInput
              type="number"
              name="account_number"
              value={checkClaimantValues.account_number}
              onIonChange={handleChange}
              className={!validity.account_number ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.account_number && <p><IonLabel color="danger">{languageData.valid_bank_account}</IonLabel></p>}
          <IonButton type="submit">
            {languageData.Submit}
          </IonButton>
        </form>

      </div>
    </div>
  );
};

export default Step4Form;
