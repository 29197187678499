import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonButtons, IonMenuButton, IonLabel, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonBadge, IonModal, IonToast, IonText } from '@ionic/react';
import { debug, error } from 'console';
import { alertCircleOutline, checkmarkCircleOutline, createOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateClaim } from '../services/api';
import BadgeStatus from './BadgeStatus';
import { LanguageContext } from '../services/LanguageContext';

interface ItemPageProps {
  claimNumber: string;
}
interface itemProps {
  item: {
    "claim_number": string,
    "debtor_details": {
      "first_name": string,
      "last_name": string,
      "id_number": string,
      "phone_number": string,
      "email": string,
      "city": string,
      "street": string,
      "house_number": string,
      "zip": string,
      "id_picture": string
    },
    "debts": [
      {
        "account_number": string,
        "amount": string,
        "bank_id": string,
        "bank_name": string,
        "branch": string,
        "check_number": string,
        "date": string,
        "bank_account_picture": string
      }
    ]
  }
}
const ItemPage: React.FC<ItemPageProps> = ({ claimNumber }) => {

  const modal = useRef<HTMLIonModalElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { languageData } = useContext(LanguageContext);


  const [error, setError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const [itemData, setItemData] = useState<any>(null);
  const location = useLocation();
  const history = useHistory();

  const state = location.state as itemProps;
  const editClaim = (claim: any) => {
    console.log('single', claim)
    let item = {
      ...claim.debtor_details,
      ...claim.debts[0]
    }
    console.log('item', JSON.stringify(item))
    localStorage.setItem('claim_number', claim.claim_number);
    if (claim.debts.length > 0) {

      localStorage.setItem('CheckDebtor', claim.debts[0].bank_account_picture);
    }
    localStorage.setItem('checkDebtorValues', JSON.stringify(item));
    history.push(`/new-claim/1`);
  }
  useEffect(() => {
    setItemData(state.item);
  }, []);

  if (!state.item) {
    return <div>{languageData.No_item}.</div>;
  }
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString([]);
  }

  const close = () => {
    setShowModal(false)
    modal.current?.dismiss();

  }
  const confirm = async () => {
    const body: any = {
      "id_number": localStorage.getItem('idNumber'),
      "claim_details": {
        "claim_number": itemData?.claim_number,
        "status": "canceled",
        "claim_date": itemData?.claim_date,
        "debtor_details": itemData?.debtor_details,
        "debts": itemData?.debts
      },
    };
    console.log('body', body)
    let confirmed: any = await updateClaim(body).then((res) => {
      console.log('res', res)
      switch (res.status) {
        case 404:
          setError(true);
          setResponseMessage(languageData.CancelMessageError);
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage(languageData.CancelMessageSuccess)
          setIsOpen(true);
          itemData.status = 'canceled'

          break;
        default:
          setError(true);
          setResponseMessage(languageData.CancelMessageError);
          setIsOpen(true);
          break;
      }


    }).finally(() => { close() });

  }

  const domain = `${window.location.protocol}//${window.location.host}`;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{languageData.claim} - {claimNumber}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='ion-padding'>
        <div className="main_container">
          {itemData ? (
            <>

              <h1> {languageData.Claim_Details}</h1>
              {(itemData.status === 'in_progress' || itemData.status === 'in progress') && (
                <>

                  <small>Open this link in incoginto mode to see the debtor flow:<br />
                    <a href={`${domain}/view/${claimNumber}`}>
                      {domain}/view/{claimNumber}</a></small>
                </>
              )}
              <IonCard >
                <IonCardHeader>
                  <div className='header_buttons'>
                    <div><IonLabel slot="end">
                      <BadgeStatus bStatus={itemData.status.toLowerCase()} />
                    </IonLabel></div>
                    <IonLabel>

                      {(itemData.status === 'new') && (
                        <IonButton color="secondary" onClick={() => { editClaim(itemData) }}>{languageData.edit}</IonButton>
                      )}
                      {(itemData.status !== 'canceled') && (
                        <IonButton id="cancelClaim" color="tertiary" onClick={() => { setShowModal(true) }}>{languageData.Cancel}</IonButton>
                      )}

                    </IonLabel>
                  </div>
                  <IonCardTitle>{languageData.Claim} {itemData.claim_number}</IonCardTitle>
                  <IonCardSubtitle>{languageData.Date}: {formatDate(itemData.claim_date)}</IonCardSubtitle>

                </IonCardHeader>

                <IonCardContent>

                  <IonLabel> {languageData.Debtor}: {itemData.debtor_details.first_name} {itemData.debtor_details.last_name}</IonLabel><IonLabel color="danger">
                    <h1>{languageData.Amount}: {itemData.debts[0].amount}₪</h1>
                  </IonLabel>
                </IonCardContent>
              </IonCard>
              <h2>{languageData.Debor_Details}</h2>
              <IonList>
                <IonItem><IonLabel>{languageData.First_Name}:</IonLabel>{itemData.debtor_details.first_name}</IonItem>
                <IonItem><IonLabel>{languageData.Last_Name}:</IonLabel>{itemData.debtor_details.last_name}</IonItem>
                <IonItem><IonLabel>{languageData.ID_Number}:</IonLabel>{itemData.debtor_details.id_number}</IonItem>
                <IonItem><IonLabel>{languageData.email}:</IonLabel>{itemData.debtor_details.email}</IonItem>
                <IonItem><IonLabel>{languageData.Phone_Number}:</IonLabel>{itemData.debtor_details.phone_number}</IonItem>
                <IonItem><IonLabel>{languageData.City}:</IonLabel>{itemData.debtor_details.city}</IonItem>
                <IonItem><IonLabel>{languageData.Street}:</IonLabel>{itemData.debtor_details.street}</IonItem>
                <IonItem><IonLabel>{languageData.House_Number}:</IonLabel>{itemData.debtor_details.house_number}</IonItem>
                <IonItem><IonLabel>{languageData.ZIP_Code}:</IonLabel>{itemData.debtor_details.zip}</IonItem>
                <IonItem><IonLabel>{languageData.ID_Picture}:</IonLabel>{itemData.debtor_details.id_picture}</IonItem>
              </IonList>
              <h2>{languageData.Debts}</h2>
              <IonList>


                {itemData.debts.map((debt: any, index: any) => {
                  // console.log(debt);
                  return (
                    <div className="item" key={index}>
                      <IonList>
                        <IonItem><IonLabel>{languageData.Date}:</IonLabel>{formatDate(debt.date)} </IonItem>
                        <IonItem><IonLabel>{languageData.Check_Number}:</IonLabel>{debt.check_number}</IonItem>
                        <IonItem><IonLabel>{languageData.Amount}:</IonLabel>{debt.amount}₪</IonItem>
                        <IonItem><IonLabel>{languageData.Account_number}:</IonLabel>{debt.account_number}</IonItem>
                        <IonItem><IonLabel>{languageData.Bank_Number}:</IonLabel>{debt.bank_id}</IonItem>
                        <IonItem><IonLabel>{languageData.Bank_Name}:</IonLabel>{debt.bank_name}</IonItem>
                        <IonItem><IonLabel>{languageData.Branch_number}:</IonLabel>{debt.branch}</IonItem>
                        <IonItem><IonLabel>{languageData.Check_Picture}:</IonLabel><img alt="bank account" src={`data:image/jpg;base64,${debt.bank_account_picture}`} height="100" /></IonItem>
                      </IonList>

                    </div>
                  );
                })}
              </IonList>
              {/* Display other debtor details... */}
            </>
          ) : (
            <p>Loading item data...</p>
          )}

          <IonModal ref={modal} isOpen={showModal}  >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => close()}>{languageData.Cancel}</IonButton>
                </IonButtons>
                <IonTitle>{languageData.Delete_Claim}</IonTitle>
                <IonButtons slot="end">
                  <IonButton strong={true} onClick={() => confirm()}>
                    {languageData.Confirm}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

              <IonText>{languageData.account_deletetion_messsage}
              </IonText>


            </IonContent>
          </IonModal>
          <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />
        </div>
      </IonContent>
    </IonPage>

  );
};

export default ItemPage;
