import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useEffect, useRef, useState, useContext } from 'react';
import { LanguageContext } from '../services/LanguageContext';
import messages_en from '../services/i18n/en.json';
import messages_he from '../services/i18n/he.json';
import messages_ru from '../services/i18n/ru.json';
import messages_fr from '../services/i18n/fr.json';
import { getGeolocation } from "../services/api";
interface LocationProps {
  location?: string
}

const LanguageSwitcher: React.FC<LocationProps> = ({ location }): JSX.Element => {
  const defaultLanguage = localStorage['language'] ? localStorage['language'] : 'en';
  // console.log('setDefaultLanguage',defaultLanguage)
  if (defaultLanguage === "he") {
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.getElementsByTagName('html')[0].classList.add("rtl");
  }
  const languageList = [
    { name: 'English', code: 'en', lang: 'English' },
    { name: 'עברית', code: 'he', lang: 'Hebrew' },
    { name: 'русский', code: 'ru', lang: 'Russian' },
    { name: 'Française', code: 'fr', lang: 'French' }
  ];

  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  const { languageData, setLanguageData } = useContext(LanguageContext);

  const onChangeLanguage = (e: any) => {

    const selectedLanguage = e.target.value;
    setCurrentLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage)
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.getElementsByTagName('html')[0].classList.remove("rtl");
    if (selectedLanguage === "he") {
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      document.getElementsByTagName('html')[0].classList.add("rtl");
    }
    loadLanguage(selectedLanguage)

  }
  const onFlagClick = (language: string) => {

    setCurrentLanguage(language);
    localStorage.setItem('language', language)
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    if (language === "he") {
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }
    loadLanguage(language)
  }
  const loadLanguage = (language: string) => {

    const messages: { [index: string]: any } = {

      'en': messages_en,
      'he': messages_he,
      'ru': messages_ru,
      'fr': messages_fr,

    };
    localStorage.setItem('tr', JSON.stringify(messages[language]));
    setLanguageData(messages[language]);
    window.dispatchEvent(new Event("storage"));
    return;
  }

  useEffect(() => {

    if (!localStorage['language']) {
      getGeolocation()
        .then((response) => {

          if (response && response.YourFuckingCountry) {

            switch (response.YourFuckingCountryCode) {
              case 'IL':
                document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
                loadLanguage("he")
                break;

              default:
                loadLanguage("en")
                break;
            }
            // setuserCountry(response.YourFuckingCountryCode); 
            // loadLanguage(response.YourFuckingCountryCode)
          }
        })
    }
    else {
      loadLanguage(localStorage['language'])
    }

  }, []);

  if (location && location === "login") {
    return (
      <div className="flag_wrapper" >


        {
          languageList.map((language, index) => (
            <img key={index} alt={language.name} src={`/assets/flags/${language.code}.svg`} onClick={() => onFlagClick(language.code)} />
          ))
        }

      </div>
    );
  }
  return (
    <IonItem >

      <IonLabel>{languageData.language}</IonLabel>
      <IonSelect interface="action-sheet" value={currentLanguage} aria-label={languageData.language} placeholder={languageData.language} onIonChange={(e) => onChangeLanguage(e)} defaultValue={currentLanguage}>
        {
          languageList.map((language, index) => (
            <IonSelectOption key={index} value={language.code} >{language.name}</IonSelectOption>
          ))
        }
      </IonSelect>
    </IonItem>
  );
};

export default LanguageSwitcher;


