import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonDatetime, IonDatetimeButton, IonModal, IonToast, IonHeader, IonButtons, IonContent, IonText, IonTitle, IonToolbar, IonRadio, IonRadioGroup, IonCheckbox } from '@ionic/react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { getGeolocation, updateClaim } from '../../services/api';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../../services/LanguageContext';
// import '../../pages/Page.css';
interface CheckDebtorValues {
  first_name?: string;
  last_name?: string;
  id_number?: string;
  email?: string;
  phone_number?: string;
  city?: string;
  street?: string;
  address?: string;
  house_number?: string;
  zip?: string;
  check_number?: number;
  date?: string;
  amount?: number;
  bank_id?: number;
  bank_name?: string;
  branch?: number;
  account_number?: number;
  bank_account_picture?: string;
  bank_account_picture_guid?: string;

}
interface ClaimValues {
  id_number?: string,
  claim_details: {
    claim_date?: string;
    claim_number?: string;
    claim_type?: string;
    status?: string;
    debtor_details?: {
      first_name?: string;
      last_name?: string;
      id_number?: string;
      email?: string;
      phone_number?: string;
      city?: string;
      street?: string;
      house_number?: string;
      zip?: string;

    },
    debts?: [
      {
        check_number?: number;
        amount?: number;
        date?: string;
        bank_name?: string;
        bank_id?: number;
        branch?: number;
        account_number?: number;
        bank_account_picture?: string;
        bank_account_picture_guid?: string;

      }
    ]
  }
}
type Props = {
  onClick: any;
  checkResult: any;
};

const Step6Form: React.FC<Props> = ({ onClick, checkResult }): JSX.Element => {
  const { languageData } = useContext(LanguageContext);


  const [idNumberError, setIdNumberError] = useState('');
  const [userCountry, setUserCountry] = useState();

  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>();
  const [notValidId, setNotValidId] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const initialCheckValues: CheckDebtorValues = {
    first_name: '',
    last_name: '',
    id_number: '',
    email: '',
    phone_number: '',
    city: '',
    street: '',
    house_number: '',
    zip: '',
    check_number: undefined,
    date: '',
    amount: undefined,
    bank_id: undefined,
    bank_name: '',
    branch: undefined,
    account_number: undefined,
    bank_account_picture: '',
  };
  const [error, setError] = useState(false)
  const [claimOption, setClaimOption] = useState('option1')
  const [responseMessage, setResponseMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false);

  const IsraelCities = ['Tel Aviv', 'Jerusalem', 'Haifa', 'Rishon LeZion', 'Petah Tikva'];

  const [checkDebtorValues, setCheckDebtorValues] = useState<CheckDebtorValues>(() => {
    const storedCheckValues = localStorage.getItem('checkDebtorValues');
    return storedCheckValues ? JSON.parse(storedCheckValues) : initialCheckValues;
  });

  const [validity, setValidity] = useState({
    first_name: true,
    last_name: true,
    id_number: true,
    email: true,
    phone_number: true,
    city: true,
    street: true,
    house_number: true,
    zip: true,
    check_number: true,
    date: true,
    amount: true,
    bank_id: true,
    bank_name: true,
    branch: true,
    account_number: true,
    bank_account_picture: true,
  });
  function IDValidator(id: any) {

    setCheckDebtorValues((prevValues) => ({
      ...prevValues,
      ['id_number']: id,
    }));
    if (id.length !== 9 || isNaN(id)) {  // Make sure ID is formatted properly
      setIdNumberError('Id number to short');
      setNotValidId(true)
    }
    let sum = 0, incNum;
    for (let i = 0; i < id.length; i++) {
      incNum = Number(id[i]) * ((i % 2) + 1);  // Multiply number by 1 or 2
      sum += (incNum > 9) ? incNum - 9 : incNum;  // Sum the digits up and add to total
    }
    if (!(sum % 10 === 0)) {
      console.log(sum % 10 === 0);
      setIdNumberError('Id Number not valid')
      setNotValidId(true)

    } else {
      setIdNumberError('')
      setNotValidId(false)

    }
    return (sum % 10 === 0);
  }
  useEffect(() => {

    getGeolocation().then((response) => {

      if (response && response.YourFuckingCountry) {
        setUserCountry(response.YourFuckingCountryCode);

      }
    });
  }, []);
  useEffect(() => {
    console.log('checkDebtorValues', checkDebtorValues)
    localStorage.setItem('checkDebtorValues', JSON.stringify(checkDebtorValues));
  }, [checkDebtorValues]);

  const handleChange = (e: any) => {

    const { name, value } = e.target;
    setCheckDebtorValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handlePhoneChange = (phone: any) => {
    if (phone) {
      let isValidPhone = isValidPhoneNumber(phone);

      setValidPhoneNumber(isValidPhone);
      //   if (isValidPhone) {
      setCheckDebtorValues((prevValues) => ({
        ...prevValues,
        ['phone_number']: phone,
      }));
      //   }
    }
  };
  const handleSubmit = async (data: any, btn?: string) => {
    console.log("data", data);
    console.log("checkDebtorValues", checkDebtorValues);

    if (validateForm()) {
      try {
        await saveFormData(checkDebtorValues, btn); // Call the API to save the form data
      } catch (error) {
        console.log('API request error:', error);
        // Handle the error here
      }
    }
  };
  const getCurrentDate = (separator = '') => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }
  const saveFormData = async (formData: CheckDebtorValues, btn?: string) => {

    const claimant_id_number = localStorage.getItem('idNumber') || '';
    const claim_number = localStorage.getItem('claim_number') || '';
    const CheckDebtor = localStorage.getItem('CheckDebtor') || '';
    console.log('formData', formData)
    const body: ClaimValues = {
      "id_number": claimant_id_number,
      "claim_details": {
        "claim_date": getCurrentDate("-"),
        "claim_type": claimOption,
        "debtor_details": {
          "first_name": formData.first_name,
          "last_name": formData.last_name,
          "id_number": formData.id_number,
          "email": formData.email,
          "phone_number": formData.phone_number,
          "city": formData.city,
          "street": formData.street,
          "house_number": formData.house_number,
          "zip": formData.zip,

        },
        "debts": [
          {
            "check_number": formData.check_number,
            "amount": formData.amount,
            "date": formData.date,
            "bank_name": formData.bank_name,
            "bank_id": formData.bank_id,
            "branch": formData.branch,
            "account_number": formData.account_number,
            "bank_account_picture_guid": formData.bank_account_picture_guid,
          }
        ]
      },
    };

    if (claim_number) {
      body.claim_details.claim_number = claim_number
    }
    const updateRes: any = await updateClaim(body).then((res) => {
      console.log(res);
      console.log('btn', btn);
      localStorage.setItem('current_claim', JSON.stringify(body))
      switch (res.status) {
        case 404:
          setError(true);
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage(languageData.saveDataMessageSuccess)
          setIsOpen(true);
          localStorage.setItem('claim_number', res.data.claim_number)

          onClick();
          break;
        default:
          setError(true);
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
      }
    });
  };

  const validateForm = () => {
    const {
      first_name,
      last_name,
      id_number,
      email,
      phone_number,
      city,
      street,
      house_number,
      zip,
      check_number,
      date,
      amount,
      bank_id,
      bank_name,
      branch,
      account_number,
      bank_account_picture,
    } = checkDebtorValues;
    const firstNameValid = first_name?.trim() !== '';
    const lastNameValid = last_name?.trim() !== '';
    const idNumberValid = IDValidator(id_number || '');
    const emailValid = email?.trim() !== '';
    const phone_numberValid = isValidPhoneNumber(phone_number || '');
    const cityValid = city?.trim() !== '';
    const streetValid = street?.trim() !== '';
    const houseNumberValid = house_number?.trim() !== '';
    const zipValid = zip?.trim() !== '';
    const chDateValid = date?.trim() !== '';
    const chBankValid = bank_name?.trim() !== '';
    let chCheckNumberValid = false;
    if (typeof check_number === 'string') {
      chCheckNumberValid = !isNaN(check_number) && !isNaN(parseFloat(check_number));
    }

    let chAmountValid = false;
    if (typeof amount === 'string') {
      chAmountValid = !isNaN(amount) && !isNaN(parseFloat(amount));
    }
    let chNumberValid = false;
    if (typeof bank_id === 'string') {
      chNumberValid = !isNaN(bank_id) && !isNaN(parseFloat(bank_id));
    }

    let chBankBranchValid = false;
    if (typeof branch === 'string') {
      chBankBranchValid = !isNaN(branch) && !isNaN(parseFloat(branch));
    }

    let chBankAccountValid = false;
    if (typeof account_number === 'string') {
      chBankAccountValid = !isNaN(account_number) && !isNaN(parseFloat(account_number));
    }

    setValidity({
      first_name: firstNameValid,
      last_name: lastNameValid,
      id_number: idNumberValid,
      email: emailValid,
      phone_number: phone_numberValid,
      city: cityValid,
      street: streetValid,
      house_number: houseNumberValid,
      zip: zipValid,
      check_number: chCheckNumberValid,
      date: chDateValid,
      amount: chAmountValid,
      bank_id: chNumberValid,
      bank_name: chBankValid,
      branch: chBankBranchValid,
      account_number: chBankAccountValid,
      bank_account_picture: true,
    });

    return (
      firstNameValid &&
      lastNameValid &&
      idNumberValid &&
      emailValid &&
      phone_numberValid &&
      cityValid &&
      streetValid &&
      houseNumberValid &&
      zipValid &&
      chNumberValid &&
      chBankValid &&
      chBankBranchValid &&
      chBankAccountValid
    );
  };
  const formRef = useRef<HTMLFormElement>(null);


  const radioGroupChange = (event: any) => {
    console.log('event', event.detail.value)
    setClaimOption(event.detail.value);

  }


  return (
    <div className="container">
      <div>
        <h2>{languageData.Step_6}</h2>
        <form id="formh" ref={formRef}>
          <IonItem>
            <IonLabel position="floating">{languageData.First_Name}:</IonLabel>
            <IonInput
              type="text"
              name="first_name"
              value={checkDebtorValues.first_name}
              onIonChange={handleChange}
              className={!validity.first_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.first_name && <p><IonLabel color="danger">{languageData.valid_first_name}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Last_Name}:</IonLabel>
            <IonInput
              type="text"
              name="last_name"
              value={checkDebtorValues.last_name}
              onIonChange={handleChange}
              className={!validity.last_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.last_name && <p><IonLabel color="danger">{languageData.valid_last_name}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.ID_Number}</IonLabel>
            <IonInput
              type="number"
              minlength={9}
              maxlength={9}
              value={checkDebtorValues.id_number}
              onIonChange={(e) => IDValidator(e.detail.value)}
            ></IonInput>
            {idNumberError && (
              <IonLabel color="danger">  {idNumberError}</IonLabel>

            )}
          </IonItem>

          {!validity.id_number && <p><IonLabel color="danger">{languageData.valid_id_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.email}:</IonLabel>
            <IonInput
              type="email"
              name="email"
              value={checkDebtorValues.email}
              onIonChange={handleChange}
              className={!validity.email ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.email && <p><IonLabel color="danger">{languageData.email_valid}</IonLabel></p>}
          <IonItem>

            <IonLabel position="stacked">{languageData.Phone_Number}:</IonLabel>
            <PhoneInput
              name="phone_number"
              id="phone_number"
              placeholder="Enter phone number"
              defaultCountry={userCountry}
              value={checkDebtorValues.phone_number}
              onChange={handlePhoneChange}
            />
          </IonItem>
          {(!validity.phone_number) && <p><IonLabel color="danger">{languageData.valid_phone_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.City}:</IonLabel>
            <IonSelect
              name="city"
              value={checkDebtorValues.city}
              onIonChange={handleChange}
              className={!validity.city ? 'invalid' : ''}
              interface="action-sheet"
            >
              <IonSelectOption value="">{languageData.choose_city}</IonSelectOption>
              {IsraelCities.map((city) => (
                <IonSelectOption key={city} value={city}>
                  {city}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {!validity.city && <p><IonLabel color="danger">{languageData.valid_city}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Street}:</IonLabel>
            <IonInput
              type="text"
              name="street"
              value={checkDebtorValues.street || checkDebtorValues.address}
              onIonChange={handleChange}
              className={!validity.street ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.street && <p><IonLabel color="danger">{languageData.valid_street}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.House_Number}:</IonLabel>
            <IonInput
              type="text"
              name="house_number"
              value={checkDebtorValues.house_number}
              onIonChange={handleChange}
              className={!validity.house_number ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.house_number && <p><IonLabel color="danger">{languageData.valid_house_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.ZIP_Code}:</IonLabel>
            <IonInput
              type="text"
              name="zip"
              value={checkDebtorValues.zip}
              onIonChange={handleChange}
              className={!validity.zip ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.zip && <p><IonLabel color="danger">{languageData.valid_zip_code}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Check_Number}:</IonLabel>
            <IonInput
              type="number"
              name="check_number"
              value={checkDebtorValues.check_number}
              onIonChange={handleChange}
              className={!validity.check_number ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.check_number && <p><IonLabel color="danger">{languageData.valid_check_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Check_Date}:</IonLabel>



            <IonInput
              type="date"
              name="date"
              value={checkDebtorValues.date}
              onIonChange={handleChange}
              className={!validity.date ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.date && <p><IonLabel color="danger">{languageData.valid_check_date}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Amount}:</IonLabel>
            <IonInput
              type="number"
              name="amount"
              value={checkDebtorValues.amount}
              onIonChange={handleChange}
              className={!validity.amount ? 'invalid' : ''}

            ></IonInput>
          </IonItem>
          {!validity.amount && <p><IonLabel color="danger">{languageData.valid_amount}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Bank_Number}:</IonLabel>
            <IonInput
              type="number"
              name="bank_id"
              value={checkDebtorValues.bank_id}
              onIonChange={handleChange}
              className={!validity.bank_id ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.bank_id && <p><IonLabel color="danger">{languageData.valid_bank_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Bank_Name}:</IonLabel>
            <IonInput
              type="text"
              name="bank_name"
              value={checkDebtorValues.bank_name}
              onIonChange={handleChange}
              className={!validity.bank_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.bank_name && <p><IonLabel color="danger">{languageData.valid_bank}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Branch_Number}:</IonLabel>
            <IonInput
              type="number"
              name="branch"
              value={checkDebtorValues.branch}
              onIonChange={handleChange}
              className={!validity.branch ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.branch && <p><IonLabel color="danger">{languageData.valid_branch}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Account_Number}:</IonLabel>
            <IonInput
              type="number"
              name="account_number"
              value={checkDebtorValues.account_number}
              onIonChange={handleChange}
              className={!validity.account_number ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.account_number && <p><IonLabel color="danger">{languageData.valid_bank_account}</IonLabel></p>}


        </form>
        <IonButton onClick={(e) => { handleSubmit(e) }}>
          {languageData.Submit}
        </IonButton>


        <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />

      </div >
    </div >
  );
};

export default Step6Form;
