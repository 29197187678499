import { IonButton, IonInput } from '@ionic/react';
import CheckComponent from '../CheckComponent';
import { useContext } from 'react';
import { LanguageContext } from '../../services/LanguageContext';



type CheckResult = {
  response: string;
  // Include other properties from the tazRes object as needed
};
type Props = {
  onCheckResult: (result: CheckResult) => void;
};
const Step3Form: React.FC<Props> = ({ onCheckResult }): JSX.Element => {
  const { languageData } = useContext(LanguageContext);
  const handleCheckResult = (result: CheckResult) => {
    onCheckResult(result); // Send the result to the App component
    // You can perform any necessary actions with the result here
  };

  return (
    <div>
      <h1>{languageData.DebtorIdentification}</h1>
      <h3>{languageData.DebtorIdentificationSubtitle}</h3>
      <CheckComponent onCheckResult={handleCheckResult} type="debtor" />
    </div>
  );
};

export default Step3Form;
