import { IonButton, IonInput } from '@ionic/react';
import Taz from '../Taz';
import { useContext } from 'react';
import { LanguageContext } from '../../services/LanguageContext';

type TazResult = {
  response: string;
  // Include other properties from the tazRes object as needed
};
type Props = {
  onTazResult: (result: TazResult) => void;
};
const Step1Form: React.FC<Props> = ({ onTazResult }): JSX.Element => {
  const { languageData } = useContext(LanguageContext);
  const handleTazResult = (result: TazResult) => {
    onTazResult(result); // Send the result to the App component
    // You can perform any necessary actions with the result here
  };
  return (
    <div>
      <h1>{languageData.ClaimantIdentification}</h1>
      <h3>{languageData.ClaimantIdentificationSubtitle}</h3>
      <Taz onTazResult={handleTazResult} />
    </div>
  );
};

export default Step1Form;
