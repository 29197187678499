import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { useContext } from 'react';
import { LanguageContext } from '../services/LanguageContext';

interface OptionClaimProps {
    claimOption: string;
}
const ThankYou: React.FC<OptionClaimProps> = ({ claimOption }) => {
    const { languageData } = useContext(LanguageContext);
    const history = useHistory();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className='yellow_bg'>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{languageData.thankYouTitle}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className='ion-padding yellow_bg'>

                <IonCard className='tyCard'>
                    <IonCardHeader>
                        <IonCardTitle>{languageData.thankYou}</IonCardTitle>
                        <IonCardSubtitle>{languageData.thankYouSubtitle}</IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <h1>{claimOption === "option1" ? languageData.thankYouOption1Title : languageData.thankYouOption2Title}
                        </h1>
                        <p>{claimOption === "option1" ? languageData.thankYouOption1 : languageData.thankYouOption2}</p>

                        {claimOption === "option2" && (
                            <p className="thankYouCalendly">{languageData.thankYouCalendly}<br /><a target="blank" href="https://calendly.com/lawfirm-demo/30min">https://calendly.com/lawfirm-demo/30min</a></p>
                        )}

                        <IonButton onClick={() => history.push('/')}>{languageData.goToDashbord}</IonButton>
                    </IonCardContent>

                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default ThankYou;
