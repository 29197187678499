import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonToast } from '@ionic/react';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { getGeolocation, updateClaimant } from '../../services/api';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../../services/LanguageContext';

interface TazValues {

  first_name?: string;
  last_name?: string;
  id_number?: string;
  city?: string;
  email?: string;
  phone_number?: string;
  street?: string;
  house_number?: number;
  zip?: number;
  id_picture?: string;
  id_picture_guid?: string;
}

type Props = {
  onClick: any;
  tazResult: any;
};

const Step2Form: React.FC<Props> = ({ onClick, tazResult }): JSX.Element => {
  const [error, setError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const { languageData } = useContext(LanguageContext);

  const [userCountry, setUserCountry] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>();
  // const [id_number, setIdNumber] = useState('');
  const [idNumberError, setIdNumberError] = useState('');
  const [notValidId, setNotValidId] = useState(false);


  const IsraelCities = ['Tel Aviv', 'Jerusalem', 'Haifa', 'Rishon LeZion', 'Petah Tikva'];

  const initialTazValues: TazValues = {
    first_name: '',
    last_name: '',
    id_number: '',
    city: '',
    email: '',
    phone_number: '',
    street: '',
    house_number: undefined,
    zip: undefined,
  };

  const [tazValues, setTazValues] = useState<TazValues>(() => {
    const storedTazValues = localStorage.getItem('tazValues');
    return storedTazValues ? JSON.parse(storedTazValues) : initialTazValues;
  });

  const [validity, setValidity] = useState({
    first_name: true,
    last_name: true,
    id_number: true,
    city: true,
    email: true,
    phone_number: true,
    street: true,
    house_number: true,
    zip: true,
  });
  function IDValidator(id: any) {

    setTazValues((prevValues) => ({
      ...prevValues,
      ['id_number']: id,
    }));
    if (id.length !== 9 || isNaN(id)) {  // Make sure ID is formatted properly
      setIdNumberError('Id number to short');
      setNotValidId(true)
    }
    let sum = 0, incNum;
    for (let i = 0; i < id.length; i++) {
      incNum = Number(id[i]) * ((i % 2) + 1);  // Multiply number by 1 or 2
      sum += (incNum > 9) ? incNum - 9 : incNum;  // Sum the digits up and add to total
    }
    if (!(sum % 10 === 0)) {
      console.log(sum % 10 === 0);
      setIdNumberError('Id Number not valid')
      setNotValidId(true)

    } else {
      setIdNumberError('')
      setNotValidId(false)

    }
    return (sum % 10 === 0);
  }
  useEffect(() => {
    localStorage.setItem('tazValues', JSON.stringify(tazValues));
  }, [tazValues]);

  useEffect(() => {
    getGeolocation().then((response) => {
      if (response && response.YourFuckingCountry) {
        setUserCountry(response.YourFuckingCountryCode);
      }
    });

  }, []);
  // useEffect(() => {
  //   console.log('effext')
  //   if(tazResult)
  //   setTazValues(tazResult);
  //   console.log('tazResult2',tazResult)
  // }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (!tazValues) {
      setTazValues(initialTazValues);
    }
    setTazValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePhoneChange = (phone: any) => {
    if (phone) {
      let isValidPhone = isValidPhoneNumber(phone);
      setValidPhoneNumber(isValidPhone);
      if (isValidPhone) {
        setTazValues((prevValues) => ({
          ...prevValues,
          ['phone_number']: phone,
        }));
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {


    console.log('tazValues', tazValues)
    e.preventDefault();
    if (!tazValues) {
      return;
    }

    if (validateForm()) {

      try {
        await saveFormData(tazValues); // Call the API to save the form data

      } catch (error) {
        console.log('API request error:', error);
        // Handle the error here
      }
    }
  };

  const saveFormData = async (formData: TazValues) => {
    const taz = localStorage.getItem('Taz')
    console.log('formData', formData)
    const body = {
      "claimant_details": {
        "personal_details": {
          "first_name": formData.first_name,
          "last_name": formData.last_name,
          "id_number": formData.id_number,
          "phone_number": formData.phone_number,
          "email": formData.email,
          "city": formData.city,
          "street": formData.street,
          "house_number": formData.house_number,
          "zip": formData.zip,
          "id_picture_guid": formData.id_picture_guid
          // "id_picture": "tazGUID"
        },
      }
    };

    const updateRes: any = await updateClaimant(body).then((res) => {
      console.log('res', res)
      switch (res.status) {
        case 404:
          setError(true)
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage(languageData.saveDataMessageSuccess)
          setIsOpen(true);
          onClick();

          break;
        default:
          setError(true)
          setResponseMessage(languageData.saveDataMessageError);
          setIsOpen(true);
          break;
      }

    });


  };

  const validateForm = () => {
    const { first_name, last_name, id_number, city, email, phone_number, street, house_number, zip } = tazValues;
    const first_nameValid = first_name?.trim() !== '';
    const last_nameValid = last_name?.trim() !== '';
    const cityValid = city?.trim() !== '';
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email || '');
    const phone_numberValid = isValidPhoneNumber(phone_number || '');
    const streetValid = street?.trim() !== '';
    const id_numberValid = IDValidator(id_number);
    console.log('id_numberValid', id_numberValid);
    // const stNumberValid = Number.isInteger(house_number);
    let stNumberValid = false;
    if (typeof house_number === "string") { // we only process strings!  
      stNumberValid = !isNaN(house_number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(house_number)) // ...and ensure strings of whitespace fail
    }
    let stZipValid = false;
    if (typeof zip === "string") { // we only process strings!  
      stZipValid = !isNaN(zip) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(zip)) // ...and ensure strings of whitespace fail
    }
    setValidity({
      first_name: first_nameValid,
      last_name: last_nameValid,
      id_number: id_numberValid,
      city: cityValid,
      email: emailValid,
      phone_number: phone_numberValid,
      street: streetValid,
      house_number: stNumberValid,
      zip: stZipValid,
    });

    return (
      first_nameValid &&
      last_nameValid &&
      id_numberValid &&
      cityValid &&
      emailValid &&
      phone_numberValid &&
      streetValid &&
      stNumberValid &&
      stZipValid
    );
  };

  return (
    <div className="container">
      <div>
        <h2>{languageData.Step_2}</h2>
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel position="floating">{languageData.ID_Number}</IonLabel>
            <IonInput
              type="number"
              minlength={9}
              maxlength={9}
              value={tazValues.id_number}
              onIonChange={(e) => IDValidator(e.detail.value)}
            ></IonInput>

          </IonItem>
          {idNumberError && (
            <IonLabel color="danger">  {idNumberError}</IonLabel>

          )}
          <IonItem>
            <IonLabel position="floating">{languageData.First_Name}:</IonLabel>
            <IonInput
              type="text"
              name="first_name"
              value={tazValues.first_name}
              onIonChange={handleChange}
              className={!validity.first_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.first_name && <p><IonLabel color="danger">Please enter a valid first name</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Last_Name}:</IonLabel>
            <IonInput
              type="text"
              name="last_name"
              value={tazValues.last_name}
              onIonChange={handleChange}
              className={!validity.last_name ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.last_name && <p ><IonLabel color="danger">Please enter a valid last name</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.City}:</IonLabel>
            <IonSelect
              name="city"
              value={tazValues.city}
              onIonChange={handleChange}
              className={!validity.city ? 'invalid' : ''}
              interface="action-sheet"
            >
              <IonSelectOption value="">Select a city</IonSelectOption>
              {IsraelCities.map((city) => (
                <IonSelectOption key={city} value={city}>
                  {city}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {!validity.city && <p><IonLabel color="danger">{languageData.Choose_City}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.email}:</IonLabel>
            <IonInput
              type="email"
              name="email"
              value={tazValues.email}
              onIonChange={handleChange}
              className={!validity.email ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.email && <p><IonLabel color="danger">{languageData.valid_email}</IonLabel></p>}
          <IonItem>
            <IonLabel position="stacked">{languageData.Phone_Number}:</IonLabel>
            <PhoneInput
              name="phone_number"
              id="phone_number"
              placeholder="Enter phone number"
              defaultCountry={userCountry}
              value={tazValues.phone_number}
              onChange={handlePhoneChange}
            />
          </IonItem>
          {!validity.phone_number && <p><IonLabel color="danger">{languageData.valid_phone_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.Street}:</IonLabel>
            <IonInput
              type="text"
              name="street"
              value={tazValues.street}
              onIonChange={handleChange}
              className={!validity.street ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.street && <p><IonLabel color="danger">{languageData.valid_street}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.House_Number}:</IonLabel>
            <IonInput
              type="text"
              name="house_number"
              value={tazValues.house_number}
              onIonChange={handleChange}
              className={!validity.house_number ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.house_number && <p><IonLabel color="danger">{languageData.valid_house_number}</IonLabel></p>}
          <IonItem>
            <IonLabel position="floating">{languageData.ZIP_Code}:</IonLabel>
            <IonInput
              type="text"
              name="zip"
              value={tazValues.zip}
              onIonChange={handleChange}
              className={!validity.zip ? 'invalid' : ''}
            ></IonInput>
          </IonItem>
          {!validity.zip && <p><IonLabel color="danger">{languageData.valid_zip_code}</IonLabel></p>}

          <IonButton type="submit">
            {languageData.Submit}
          </IonButton>
        </form>
        <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />
      </div>
    </div>
  );
};

export default Step2Form;
