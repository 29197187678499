import { IonBadge } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from "../services/LanguageContext";

interface StatusProps{
  bStatus:string
}
const BadgeStatus: React.FC<StatusProps> = ({ bStatus }): JSX.Element =>{
  const [clr, setColor] = useState('');
  const { languageData } = useContext(LanguageContext);
  const [translatedStatus,setTranslatedStatus] = useState('');
  
  useEffect(() => {
    switch (bStatus) {
      case "paid":
        setColor('success');
        setTranslatedStatus(languageData.paid)
        break;
      case "canceled":
        setColor('medium');
        setTranslatedStatus(languageData.canceled)
        break;
      case "payment refused":
        case "payment_refused":
        setColor('tertiary');
        setTranslatedStatus(languageData.payment_refused)

        break;
      case "new":
        setColor('primary');
        setTranslatedStatus(languageData.new)

        break;
      case "sent":
        setColor('light');
        setTranslatedStatus(languageData.sent)

        break;
      case "in_progress":
        case "in progress":
        setColor('secondary');
        setTranslatedStatus(languageData.in_progress)

        break;
      default:
        setColor('');
        break;
    }
  }, [clr]);

  return (
    <IonBadge color={clr}>{translatedStatus}</IonBadge>
  );
};

export default BadgeStatus;
