import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonButton, IonIcon, IonButtons, IonMenuButton, IonBadge } from '@ionic/react';
import { addOutline, createOutline } from 'ionicons/icons';


import { getClaims } from '../services/api';
import { Link, useHistory } from 'react-router-dom';
import { AnyNaptrRecord } from 'dns';
import BadgeStatus from '../components/BadgeStatus';

// import { i18nConfig,str_translation } from '../services/Messages';
import { LanguageContext } from '../services/LanguageContext';


interface DashboardPageProps {
  items: any[]; // Replace 'any[]' with the appropriate type for your items array
}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const history = useHistory();

  const [items, setItems] = useState<any[]>([]);
  const [claimant, setClaimant] = useState<any>();
  const [id_number] = useState<string>(localStorage.getItem('idNumber') || '');


  const { languageData } = useContext(LanguageContext);
  // console.log('languageData',languageData);
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString([]);
  }

  const fetchItems = async () => {

    let claims = await getClaims(id_number).then((response: any) => {

      switch (response.status) {
        case 200:
          setClaimant(response.data.claimant_details);
          setItems(response.data.claims);
          break;
        case 400:
          let sd: any = []
          setItems(sd);
          break;
        default:
          break;
      }
    });



  };
  //   var hash = require('object-hash');

  //  console.log( hash({foo: 'bar'}))
  const editClaim = (claim: any) => {
    console.log('single', claim)
    const current_claim = {
      "id_number": id_number,
      "claim_details": claim
    }
    localStorage.setItem('current_claim', JSON.stringify(current_claim))
    let item = {
      ...claim.debtor_details,
      ...claim.debts[0]
    }
    console.log('item', JSON.stringify(item))
    localStorage.setItem('claim_number', claim.claim_number);
    if (claim.debts.length > 0) {

      localStorage.setItem('CheckDebtor', claim.debts[0].bank_account_picture);
    }
    localStorage.setItem('checkDebtorValues', JSON.stringify(item));
    history.push(`/new-claim/1`);
  }

  useEffect(() => {

    // Fetch data from the API and update the items state
    fetchItems();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{languageData.Dashboard}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>

        <IonList>
          {items.map((item, index) => (

            <IonItem key={index} >
              <>
                <Link className='claimItem' to={{ pathname: `/claim/${item.claim_number}`, state: { item } }}>
                  <IonLabel>{formatDate(item.claim_date)} </IonLabel>
                  <IonLabel>

                    <h2>{item.debtor_details.first_name} {item.debtor_details.last_name}</h2>
                    <p><IonLabel color="danger">{languageData.Debt_Amount}: {item.debts.length > 0 ? item.debts[0].amount : ''}₪</IonLabel></p>
                  </IonLabel>
                </Link>
                <IonLabel slot="end">

                  {(item.status === 'new') && (
                    <IonIcon className="editClaim" icon={createOutline} onClick={() => { editClaim(item) }} />
                  )}
                  <BadgeStatus bStatus={item.status.toLowerCase()} />


                </IonLabel>
              </>
            </IonItem>

          ))}
          {items.length === 0 && (
            <IonItem  >

              <div dangerouslySetInnerHTML={{ __html: languageData.no_claim }}></div>

            </IonItem>
          )}
        </IonList>
        <IonButton routerLink={"/new-claim"} routerDirection="forward" >
          <IonIcon icon={addOutline} />
          {items.length === 0 ? languageData.start_now : languageData.Add_New_Claim}
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default DashboardPage;
