import React, { useContext, useEffect, useRef, useState } from 'react';
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';
import { IonButton, IonIcon, IonLabel, IonToast } from '@ionic/react';
import { sendTaz } from '../services/api';
import { useHistory } from 'react-router';
import { alertCircleOutline, aperture, camera, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../services/LanguageContext';
import Loader from './Loader';
import { error } from 'console';

type TazResult = {
  response: string;
  // Include other properties from the tazRes object as needed
};
type Props = {
  onTazResult: (result: TazResult) => void;
};
const Taz: React.FC<Props> = ({ onTazResult }): JSX.Element => {
  const { languageData } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);


  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [img, setImageData] = useState('');
  const [display, setDisplay] = useState('none');
  const [cameraActive, setCameraActive] = useState(false);
  const [tazError, setTazError] = useState(false);
  const [tazDetails, setTazDetails] = useState<any>();
  const inputFile = useRef<HTMLInputElement | null>(null)
  const isMobile = window.innerWidth <= 768;
  const [isOpen, setIsOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('')
  const [error, setError] = useState(false)



  const [base64Image, setBase64Image] = useState<string>(() => {
    const storedTaz = localStorage.getItem('Taz');
    return storedTaz ? storedTaz : '';
  });


  const copyTextToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const startCameraPreview = async () => {
    setCameraActive(true);
    setDisplay('none');
    const cameraPreviewOptions = {
      x: 0,
      y: 0,
      width: window.innerWidth,
      height: window.innerHeight,
      position: 'rear',
      toBack: false,
      tapPhoto: false,
      tapFocus: true,
      previewDrag: false,
      parent: 'cameraPreview'
    };

    try {
      await CameraPreview.start(cameraPreviewOptions);
      // await CameraPreview.show();
      // await CameraPreview.setFlashMode('off');
    } catch (error) {
      console.log('Error starting camera preview:', error);
    }
  };

  const stopCameraPreview = async () => {
    setCameraActive(false);
    try {
      await CameraPreview.stop();
    } catch (error) {
      console.log('Error stopping camera preview:', error);
    }
  };

  const captureImage = async () => {
    try {
      const imageData = await CameraPreview.capture({
        quality: 85
      });
      cropImage(imageData.value);
    } catch (error) {
      console.log('Error capturing photo:', error);
    }
  };

  const cropImage = (base64Data: string) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      const image = new Image();
      image.src = `data:image/jpeg;base64,${base64Data}`;
      image.onload = function () {
        const imageAspectRatio = image.width / image.height;
        const targetAspectRatio = 8 / 5; //2.2857142857142856
        const cameraPreviewRef = document.getElementById('cameraPreview');

        let cropWidth, cropHeight, cropX, cropY;
        let pixelw = 0;
        if (cameraPreviewRef) {
          pixelw = (6 * cameraPreviewRef?.offsetWidth) / 100;
        }

        if (imageAspectRatio > targetAspectRatio) {
          cropHeight = image.height;
          cropWidth = cropHeight * targetAspectRatio;
          cropX = (image.width - cropWidth) / 2;
          cropY = pixelw;
        } else {
          cropWidth = image.width - (pixelw * 2);
          cropHeight = cropWidth / targetAspectRatio;
          cropX = pixelw;
          cropY = (image.height - cropHeight) / 2;
        }
        // console.log('pixelw',pixelw)
        // console.log('image.width',image.width)
        // console.log('imageAspectRatio',imageAspectRatio)
        // console.log('targetAspectRatio',targetAspectRatio)
        if (canvas) {
          canvas.width = cropWidth;
          canvas.height = cropHeight;

          ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
          setDisplay('block');
          stopCameraPreview();

          var strImage = canvas.toDataURL('image/jpeg').replace(/^data:image\/[a-z]+;base64,/, "");

          setBase64Image(strImage);
        }
      };

      // return canvas?.toDataURL('image/jpeg');
    }

    return base64Data;
  };

  const sendImage = async (base64Data: string) => {
    setLoading(true);
    const tazRes: any = await sendTaz(base64Data).then((response) => {
      console.log('response', response)
      if (response.status === 200) {
        setTazDetails(response.data);
        onTazResult(response.data);
        response.data.id_picture = base64Data;
        localStorage.setItem('Taz', base64Data)
        localStorage.setItem('id_picture_guid', response.data.id_picture_guid)
        localStorage.setItem('tazValues', JSON.stringify(response.data));
        setIsOpen(true);
        setResponseMessage(languageData.saveDataMessageSuccess)
      } else {
        setTazError(true);
        setResponseMessage(languageData.CheckMessageError)
        setIsOpen(true);
      }
    }).finally(() => { setLoading(false) });




  };



  function convertImageToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result?.toString()?.split(',')[1] || '';
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      convertImageToBase64(file)
        .then((base64String) => {
          setBase64Image(base64String);
        })
        .catch((error) => {
          console.log(languageData.imgMessageError, error);
        });
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    console.log('inputFile', inputFile)
    if (inputFile && inputFile.current) {

      inputFile.current.click();

    }
  };



  return (
    <div>
      {!cameraActive && !base64Image && (<>
        {isMobile &&
          <IonButton onClick={startCameraPreview} color="secondary"><IonIcon icon={camera} color="primary"></IonIcon></IonButton>
        }



      </>)}
      {!cameraActive && (
        <>
          <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} accept="image/jpeg, image/png" onChange={handleImageChange} />
          <p onClick={onButtonClick}> {languageData.Open_file}</p>
        </>

      )}
      {cameraActive &&
        <IonButton onClick={stopCameraPreview}>{languageData.Stop_Camera}</IonButton>
      }
      {cameraActive && <IonButton onClick={captureImage}><IonIcon icon={aperture} ></IonIcon> {languageData.Capture_Image}</IonButton>}
      <div id="cameraPreview" >
        {cameraActive && <div className="pictureOverlay tazOverlay"><div className="overlay">{languageData.IdMessageFrame}</div></div>}
      </div>

      <canvas id="canvas" ref={canvasRef} style={{ display: `${display}` }}></canvas>
      {base64Image && display === 'none' && (
        <img className='base64Img' src={`data:image/jpeg;base64,${base64Image}`} alt="Uploaded" />
      )}
      {base64Image && !cameraActive && (

        <div className='img_button_wrapper'>
          {isMobile &&
            <IonButton onClick={() => { setBase64Image(''); startCameraPreview() }} fill="outline" expand="block">{languageData.Retake_Image}</IonButton>
          }
          <IonButton onClick={() => sendImage(base64Image)} expand="block">{languageData.Validate_Image}</IonButton>
          <IonButton onClick={() => copyTextToClipboard(base64Image)}>Copy base64 img</IonButton>

        </div>


      )}
      <IonToast isOpen={isOpen} onDidDismiss={() => { setTazError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={tazError ? alertCircleOutline : checkmarkCircleOutline} color={tazError ? "danger" : "success"} />
      <Loader isloading={loading} />

    </div>
  );
};

export default Taz;
