import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getGeolocation, sendOtp, validateOtp, registerUser } from '../services/api';
import 'react-phone-number-input/style.css'
import LanguageSwitcher from '../components/LanguageSwitcher';
import { LanguageContext } from '../services/LanguageContext';


const Page: React.FC = () => {
  // const language = localStorage.getItem('tr') || '';
  const { languageData } = useContext(LanguageContext);


  const [userCountry, setuserCountry] = useState();
  const [phone_number, setPhoneNumber] = useState('');
  const [notValidPhone, setNotValidPhone] = useState(true);

  const [otpCode, setOtpCode] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [idNumberError, setIdNumberError] = useState('');
  const [Error, setError] = useState('');
  const [messsage, setMessage] = useState('');
  const [notValidId, setNotValidId] = useState(true);

  const [showOtpField, setShowOtpField] = useState(false);
  const [ShowRegister, setShowRegister] = useState(false);

  const registration = async () => {
    if (!notValidPhone && !notValidId) {
      return;
    }
    // TODO: Implement OTP code sending logic here
    const otpRes: any = await registerUser(idNumber, phone_number).then((res) => {
      console.log('otp', res.data.otp)

      switch (res.status) {
        case 200:
          if (res.data.response === "OK") {
            setShowOtpField(true);
            setShowRegister(false)
            setMessage('Please enter the one time code that you receive in your phone (' + res.data.otp + ')')
            setError('')
          } else {
            setError(res.data.response)
          }
          break;
        case 404:
          setError('API error')
          break;

        default:
          break;
      }
    });


  }

  const sendOtpCode = async () => {
    if (!notValidPhone && !notValidId) {
      return;
    }
    // TODO: Implement OTP code sending logic here
    const otpRes: any = await sendOtp(idNumber, phone_number).then((res) => {
      // console.log('otpRes.response',res)
      console.log('otp', res.data.otp)
      switch (res.status) {
        case 200:
          if (res.data.response === "OK") {
            setShowOtpField(true);
            setMessage('Please enter the one time code that you receive in your phone (' + res.data.otp + ')')
          }
          else if (res.data.response === "user_not_found") {
            setShowRegister(true)
          }
          else {
            setError(res.data.response)
          }
          break;
        case 404:
          setError('API error')
          break;

        default:
          break;
      }
    });




  }



  const verifyOtpCode = async () => {
    if (!otpCode) {
      return;
    }

    // TODO: Implement OTP code verification logic here
    const otpRes: any = await validateOtp(otpCode, idNumber, phone_number);
    // console.log('otpRes',otpRes)
    switch (otpRes.status) {
      case 200:
        if (otpRes.data.valid_otp) {
          localStorage.setItem('idNumber', otpRes.data.id_number);
          localStorage.setItem('logged', 'true');
          window.location.reload();
        }
        else {
          setError('Otp Code not valid')
        }
        break;
      case 404:
        setError('API error')
        break;
      default:

        break;
    }




  }

  function IDValidator(id: any) {

    setIdNumber(id);
    if (id.length !== 9 || isNaN(id)) {  // Make sure ID is formatted properly
      setIdNumberError('Id number to short');
      setNotValidId(false)
    }
    let sum = 0, incNum;
    for (let i = 0; i < id.length; i++) {
      incNum = Number(id[i]) * ((i % 2) + 1);  // Multiply number by 1 or 2
      sum += (incNum > 9) ? incNum - 9 : incNum;  // Sum the digits up and add to total
    }
    if (!(sum % 10 === 0)) {
      // console.log(sum % 10 === 0);
      setIdNumberError('Id Number not valid')
      setNotValidId(false)

    } else {
      setIdNumberError('')
      setNotValidId(true)

    }
    // return (sum % 10 === 0);
  }

  const handlePhoneChange = (phone: any) => {
    if (phone) {
      let isValidPhone = isValidPhoneNumber(phone);
      // console.log('isValidPhone',isValidPhone);
      if (isValidPhone) {
        setPhoneNumber(phone);
      }
      setNotValidPhone(isValidPhone);
    }
  };
  useEffect(() => {


    getGeolocation()
      .then((response) => {

        if (response && response.YourFuckingCountry) {
          // console.log(response);
          setuserCountry(response.YourFuckingCountryCode);
        }
      })
  }, []);

  return (
    <IonPage>



      <IonContent className='ion-padding'>


        <div className='login_page'>
          <LanguageSwitcher location="login" />
          <h1>{languageData.login} / {languageData.register}</h1>

          <IonItem>
            <IonLabel position="floating">{languageData.ID_Number}</IonLabel>
            <IonInput
              type="number"
              minlength={9}
              maxlength={9}
              value={idNumber}
              onIonChange={(e) => IDValidator(e.detail.value)}
            ></IonInput>
            {idNumberError && (
              <IonLabel color="danger">  {idNumberError}</IonLabel>

            )}
          </IonItem>
          <IonItem>
            <PhoneInput
              name="phoneField"
              id="phoneField"
              placeholder={languageData.Enter_phone_number}
              defaultCountry={userCountry}

              onChange={(phone) => handlePhoneChange(phone)}

            />
            {(!notValidPhone) && (
              <IonLabel color="danger">  {languageData.valid_phone_number}</IonLabel>



            )}

          </IonItem>

          {showOtpField ? (
            <IonItem>
              <IonLabel position="floating">{languageData.OTP_Code}</IonLabel>
              <IonInput
                type="number"
                value={otpCode}
                onIonChange={(e) => setOtpCode(e.detail.value!)}
              ></IonInput>
            </IonItem>
          ) : null}



          <IonButton disabled={phone_number ? false : true} expand="block" onClick={showOtpField ? verifyOtpCode : sendOtpCode}>
            {showOtpField ? languageData.verifyOTP : languageData.send}
          </IonButton>

          {(ShowRegister) && (
            <>
              <p>{languageData.userNotFound}</p>
              <IonButton disabled={phone_number ? false : true} expand="block" onClick={registration}>
                {languageData.register}
              </IonButton>
            </>
          )}
          {messsage && (
            <IonLabel>  {messsage}</IonLabel>

          )}
          {Error && (
            <IonLabel color="danger">  {Error}</IonLabel>

          )}
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Page;
