import React, { createContext, useState } from 'react';

export const LanguageContext = createContext<any>(null);
interface childrenProps{
    children?:any
}
export const LanguageProvider: React.FC<childrenProps> = ({ children }) => {
    const [languageData, setLanguageData] = useState<string>(() => {
        const tr = localStorage.getItem('tr');
        return tr ? JSON.parse(tr) : [];
      });

//   const [languageData, setLanguageData] = useState<any>(null);

  return (
    <LanguageContext.Provider value={{ languageData, setLanguageData }}>
      {children}
    </LanguageContext.Provider>
  );
};
