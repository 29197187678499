import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { getClaim, singleOtp, updateClaim, validateOtp } from '../services/api';

import { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../services/LanguageContext';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Countdown, { zeroPad } from 'react-countdown';
interface ViewClaimProps {
  claimNumber: string;
}
interface ClaimantValues {

  "personal_details": {
    "first_name": string,
    "last_name": string,
    "id_number": string,
    "phone_number": string,
    "email": string,
    "city": string,
    "street": string,
    "house_number": string,
    "zip": string,
    "id_picture": string,
  },
  "account_details": {
    "bank_name": string,
    "bank_id": string,
    "branch_name": string,
    "branch": string,
    "account_number": string,
    "bank_account_picture": string,
  }

}
interface ClaimValues {



  "claim_number": string,
  "claim_date": string,
  "debtor_details": {
    "first_name": string,
    "last_name": string,
    "id_number": string,
    "phone_number": string,
    "email": string,
    "city": string,
    "street": string,
    "house_number": string,
    "zip": string,
    "id_picture": string
  },
  "debts": [
    {
      "account_number": string,
      "amount": string,
      "bank_id": string,
      "bank_name": string,
      "branch": string,
      "check_number": string,
      "date": string,
      "bank_account_picture": string
    }
  ]


}

const ViewClaim: React.FC<ViewClaimProps> = ({ claimNumber }) => {
  const { languageData } = useContext(LanguageContext);


  const [claimNbr] = useState(claimNumber);
  const [PhoneNumber, setPhoneNumber] = useState<string>();
  const [idNumber, setIdNumber] = useState('');
  const [hiddenNumber, setHiddenNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');

  const [viewClaim, setViewClaim] = useState(false);
  const [claim, setClaim] = useState<ClaimValues>();
  const [ExpirationDate, setExpirationDate] = useState<number>();
  const [claimant, setClaimant] = useState<ClaimantValues>();

  const modal = useRef<HTMLIonModalElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const search = useLocation().search;
  const [session] = useState<any>(new URLSearchParams(search).get('session'));
  const [timeOut, SetCompleted] = useState(false)



  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString([]);
  }
  const fetchClaim = async () => {
    let claim = await getClaim(claimNbr).then((res: any) => {
      console.log('res', res)
      switch (res.status) {
        case 404:
          setError(true)
          setResponseMessage("Coulnd't retreive your debt, please check your link");

          break;
        case 200:
          setClaim(res.data.claim);
          setClaimant(res.data.claimant);
          setPhoneNumber(res.data.claim.debtor_details.phone_number);
          setIdNumber(res.data.claim.debtor_details.id_number);
          let payment = {
            paymentDate: new Date(res.data.claim.claim_date),  // Today
            paymentExpirationDate: 7  // Days to add
          };
          let date = + new Date(new Date(payment.paymentDate).setDate(payment.paymentDate.getDate() + payment.paymentExpirationDate));
          setExpirationDate(date);

          break;
        default:
          setError(true)
          setResponseMessage("Coulnd't retreive your debt, please check your link");
          ;
          break;
      }
    })
  };

  const sendOtp = async () => {
    if (PhoneNumber) {
      let otp = await singleOtp(PhoneNumber, idNumber);

      setOtpCode(otp.data.otp)
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('otp', otpCode)
    if (PhoneNumber) {

      let ValidOtp = await validateOtp(otpCode, idNumber, PhoneNumber).then((res) => {
        switch (res.status) {
          case 404:
            setError(true)
            setResponseMessage(languageData.validate_OTP_error);

            break;
          case 200:
            if (res.data.valid_otp) {
              setViewClaim(true);
            } else {
              setError(true)
              setResponseMessage(languageData.wrong_otp);
            }
            break;
          default:
            setError(true)

            setResponseMessage(languageData.validate_OTP_error);
            ;
            break;
        }
      });


    }

  }
  const updatePaymentStatus = async () => {


    const body: any = {
      "id_number": claimant?.personal_details.id_number,
      "claim_details": {
        "claim_number": claim?.claim_number,
        "status": "paid",
        "claim_date": claim?.claim_date,
        "debtor_details": claim?.debtor_details,
        "debts": claim?.debts

      },
    };
    body.claim_details.debts.payment_auth_number = session
    let PaymentStatus: any = await updateClaim(body).then((res) => {
      console.log('res', res)
      switch (res.status) {
        case 404:
          setError(true);
          setResponseMessage(languageData.payment_error);
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage(languageData.payment_success)
          setIsOpen(true);


          break;
        default:
          setError(true);
          setResponseMessage(languageData.payment_error);
          setIsOpen(true);
          break;
      }


    });



  }
  useEffect(() => {

    fetchClaim();

  }, []);
  useEffect(() => {

    if (claim && session)
      updatePaymentStatus();
  }, [claim]);


  useEffect(() => {

    sendOtp();

    let lastTwo = PhoneNumber?.substr(PhoneNumber?.length - 3);
    let firstFive = PhoneNumber?.substring(0, 6);
    setHiddenNumber(firstFive + 'XXXX' + lastTwo)
  }, [PhoneNumber]);

  const close = () => {
    setShowModal(false)
    modal.current?.dismiss();

  }
  const confirm = async () => {

    //todo if refuse
    const body: any = {
      "id_number": claimant?.personal_details.id_number,
      "claim_details": {
        "claim_number": claim?.claim_number,
        "status": "payment_refused",
        "claim_date": claim?.claim_date,
        "debtor_details": claim?.debtor_details,
        "debts": claim?.debts
      },
    };
    let confirmed: any = await updateClaim(body).then((res) => {
      console.log('res', res)
      switch (res.status) {
        case 404:
          setError(true);
          setResponseMessage("Coulnd't send your request, please try again");
          setIsOpen(true);
          break;
        case 200:
          setResponseMessage('Your request was sent')
          setIsOpen(true);


          break;
        default:
          setError(true);
          setResponseMessage("Coulnd't send your request, please try again");
          setIsOpen(true);
          break;
      }


    }).finally(() => { close() });

  }
  const [showModal, setShowModal] = useState(false);
  const paylaim = () => {
    const url = window.location.href + '?session=asdfghj9876543';
    window.location.href = url;
  }

  // Random component
  const Completionist = () => <div className='timeOut'>{languageData.paymentTimeout}</div>;
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      SetCompleted(true)
      return <Completionist />;
    } else {
      // Render a countdown
      return <span className='countdown'>{days}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };
  return (
    <IonPage>


      <IonContent className="ion-padding">
        <div className="main_container">
          {session && (
            <>
              <LanguageSwitcher location="login" />
              <IonCard >
                <IonCardContent>
                  {responseMessage}
                </IonCardContent>
              </IonCard>
            </>
          )}
          {PhoneNumber && !viewClaim && !session && (
            <>
              <LanguageSwitcher location="login" />
              <div className="main_container">

                <h1>{languageData.Important_notice}</h1>
                <h2>{languageData.open_claim_title}</h2>
                <p>{languageData.open_claim}</p>


                {ExpirationDate && (
                  <IonCard className='countdownCard'>
                    <h3>{languageData.open_claim_slogan}</h3>
                    <Countdown date={ExpirationDate} renderer={renderer} />
                  </IonCard>
                )}
                <p>{languageData.phone_otp}({hiddenNumber}) ({otpCode})</p>


                <form onSubmit={handleSubmit}>

                  <IonItem>
                    <IonLabel position="floating">{languageData.OTP_Code}</IonLabel>
                    <IonInput
                      id="otp"
                      name="otp"
                      type="number"
                      value={otpCode}
                      onIonChange={(e) => setOtpCode(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonButton disabled={PhoneNumber ? false : true} type="submit">
                    {languageData.View_Claim}
                  </IonButton>
                </form>
                {error && (
                  <IonLabel color="danger">{responseMessage}</IonLabel>

                )}
              </div>
            </>
          )}


          {viewClaim && claim && (
            <div className='single_claim_data'>

              <h1>{languageData.Claim_Details} </h1>
              <h2>{languageData.open_claim_title}</h2>
              <p>{languageData.open_claim}</p>

              <IonCard >
                <IonCardHeader>

                  <IonCardTitle>{languageData.Claim} {claim.claim_number}</IonCardTitle>
                  <IonCardSubtitle>{languageData.Date}: {formatDate(claim.claim_date)}</IonCardSubtitle>

                </IonCardHeader>

                <IonCardContent>

                  <IonLabel> {languageData.Claimant}: {claimant?.personal_details.first_name} {claimant?.personal_details.last_name}</IonLabel><IonLabel color="danger">
                    <h1>{languageData.Amount}:{claim.debts[0].amount}₪</h1>
                  </IonLabel>
                  <div className="textCenter">
                    <p className='bigText'>{languageData.open_claim_slogan} ({parseInt(claim.debts[0].amount) * 85 / 100}₪)</p>
                    <Countdown date={ExpirationDate} renderer={renderer} /></div>
                  {!timeOut && (
                    <div className='header_buttons'>
                      <IonButton onClick={() => { paylaim() }}>{languageData.Pay}</IonButton>
                      <IonButton onClick={() => { setShowModal(true) }} color="danger">{languageData.Refuse}</IonButton>
                    </div>
                  )}
                </IonCardContent>
              </IonCard>

              <h2>{languageData.Claimant_Details}</h2>
              <IonList>


                <IonItem><IonLabel>{languageData.First_Name}:</IonLabel> {claimant?.personal_details.first_name}</IonItem>
                <IonItem><IonLabel>{languageData.Last_Name}:</IonLabel>{claimant?.personal_details.last_name}</IonItem>

                <IonItem><IonLabel>{languageData.email}: </IonLabel>{claimant?.personal_details.email}</IonItem>
                <IonItem><IonLabel>{languageData.Phone_Number}: </IonLabel>{claimant?.personal_details.phone_number}</IonItem>

              </IonList>
              <h2>{languageData.Debor_Details}</h2>
              <IonList>
                <IonItem><IonLabel>{languageData.First_Name}: </IonLabel>{claim.debtor_details.first_name}</IonItem>
                <IonItem><IonLabel>{languageData.Last_Name}: </IonLabel>{claim.debtor_details.last_name}</IonItem>
                <IonItem><IonLabel>{languageData.ID_Number}: </IonLabel>{claim.debtor_details.id_number}</IonItem>
                <IonItem><IonLabel>{languageData.email}: </IonLabel>{claim.debtor_details.email}</IonItem>
                <IonItem><IonLabel>{languageData.Phone_Number}: </IonLabel>{claim.debtor_details.phone_number}</IonItem>
                <IonItem><IonLabel>{languageData.City}: </IonLabel>{claim.debtor_details.city}</IonItem>
                <IonItem><IonLabel>{languageData.Street}: </IonLabel>{claim.debtor_details.street}</IonItem>
                <IonItem><IonLabel>{languageData.House_Number}: </IonLabel>{claim.debtor_details.house_number}</IonItem>
                <IonItem><IonLabel>{languageData.ZIP_Code}: </IonLabel>{claim.debtor_details.zip}</IonItem>
                <IonItem><IonLabel>{languageData.ID_Picture}: </IonLabel>{claim.debtor_details.id_picture}</IonItem>
              </IonList>
              <h2>{languageData.Debts}</h2>
              <IonList>


                {claim.debts.map((debt: any, index: any) => {
                  // console.log(debt);
                  return (
                    <div className="item" key={index}>
                      <IonList>
                        <IonItem><IonLabel>{languageData.Date}: </IonLabel>{formatDate(debt.date)} </IonItem>
                        <IonItem><IonLabel>{languageData.Check_Number}: </IonLabel>{debt.check_number}</IonItem>
                        <IonItem><IonLabel>{languageData.Amount}: </IonLabel>{debt.amount}₪</IonItem>
                        <IonItem><IonLabel>{languageData.Account_number}: </IonLabel>{debt.account_number}</IonItem>
                        <IonItem><IonLabel>{languageData.Bank_Number}: </IonLabel>{debt.bank_id}</IonItem>
                        <IonItem><IonLabel>{languageData.Bank_Name}: </IonLabel>{debt.bank_name}</IonItem>
                        <IonItem><IonLabel>{languageData.Branch_Name}: </IonLabel>{debt.branch}</IonItem>
                        <IonItem><IonLabel>{languageData.bank_account_picture}:</IonLabel><img alt="bank account" src={`data:image/jpg;base64,${debt.bank_account_picture}`} height="100" /></IonItem>
                      </IonList>
                    </div>
                  );
                })}
              </IonList>
              {!timeOut && (
                <>
                  <IonButton onClick={() => { paylaim() }}>{languageData.Pay}</IonButton>
                  <IonButton onClick={() => { setShowModal(true) }} color="danger">{languageData.Refuse}</IonButton>
                </>
              )}
              <IonModal ref={modal} isOpen={showModal} >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={() => close()}>{languageData.Cancel}</IonButton>
                    </IonButtons>
                    <IonTitle>{languageData.Refuse_Payment}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton strong={true} onClick={() => confirm()}>
                        {languageData.Confirm}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">

                  <IonText>{languageData.Refuse_Payment_message}</IonText>
                  <div className="innerModal_buttons">
                    <IonButton onClick={() => { paylaim() }}>{languageData.Pay}</IonButton>
                    <IonButton onClick={() => confirm()} color="danger">{languageData.Refuse}</IonButton>
                  </div>


                </IonContent>
              </IonModal>
              <IonToast isOpen={isOpen} onDidDismiss={() => { setError(false); setIsOpen(false) }} message={responseMessage} duration={3000} position="top" icon={error ? alertCircleOutline : checkmarkCircleOutline} color={error ? "danger" : "success"} />


            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewClaim;

