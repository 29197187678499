import React, { useEffect, useState, useRef, useContext } from 'react';
import { IonContent, IonPage, IonInput, IonText, IonButton, IonLabel, IonItem, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonModal, IonToast } from '@ionic/react';
import { deleteUser, getClaimant, updateClaimant } from '../services/api';
import { useHistory } from 'react-router';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { LanguageContext } from '../services/LanguageContext';
import Loader from '../components/Loader';

interface PersonalDetails {
  first_name: string;
  last_name: string;
  id_number: string;
  phone_number: string;
  email: string;
  city: string;
  street: string;
  house_number: string;
  zip: string;
  id_picture: string;
  id_picture_guid: string;
}

interface AccountDetails {
  bank_name: string;
  bank_id: string;
  branch_name: string;
  branch: string;
  account_number: string;
  bank_account_picture: string;
  bank_account_picture_guid: string;
}

interface ProfileData {
  claimant_details: {
    personal_details: PersonalDetails;
    account_details: AccountDetails;
  };
}

const ProfilePage: React.FC = () => {
  const [loading, setLoading] = useState(false);


  const modal = useRef<HTMLIonModalElement>(null);
  const [messSuccess, setMessSuccess] = useState(false);
  let history = useHistory();
  const [saveProfileMessage, setSaveProfileMessage] = useState('');
  const [saveProfileError, setSaveProfileError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { languageData } = useContext(LanguageContext);
  const [base64Taz, setBase64Taz] = useState<string>(() => {
    let tazImage = localStorage.getItem('Taz');
    return tazImage ? tazImage : '';

  });
  const [base64CheckClaimant, setbase64CheckClaimant] = useState<string>(() => {
    let CheckClaimant = localStorage.getItem('CheckClaimant');
    return CheckClaimant ? CheckClaimant : '';

  });

  const [profileData, setProfileData] = useState<ProfileData>({
    claimant_details: {
      personal_details: {
        first_name: '',
        last_name: '',
        id_number: '',
        phone_number: '',
        email: '',
        city: '',
        street: '',
        house_number: '',
        zip: '',
        id_picture: '',
        id_picture_guid: '',
      },
      account_details: {
        bank_name: '',
        bank_id: '',
        branch_name: '',
        branch: '',
        account_number: '',
        bank_account_picture: '',
        bank_account_picture_guid: '',
      },
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true);
    profileData.claimant_details.personal_details.id_picture = '';
    profileData.claimant_details.account_details.bank_account_picture = '';
    profileData.claimant_details.account_details.bank_account_picture_guid = localStorage.getItem('checkClaimant_guid') || '';
    profileData.claimant_details.personal_details.id_picture_guid = localStorage.getItem('id_picture_guid') || '';
    console.log('profileData', profileData)
    updateClaimant(profileData).then((response) => {

      if (response.status === 200 && response.statusText === 'OK') {

        setSaveProfileMessage(languageData.SaveProfileMessageSuccess)
        setIsOpen(true);
      } else {
        setSaveProfileMessage(languageData.SaveProfileMessageError)
        setSaveProfileError(true);
        setIsOpen(true);

      }
    })
      .catch((e) => {
        // console.log('first', e)
        setSaveProfileMessage(languageData.SaveProfileMessageError)
        setSaveProfileError(true);
        setIsOpen(true)
      })
      .finally(() => { setLoading(false) });


  };

  const handleInputChange = (field: string, value: string) => {

    //profileData.claimant_details.account_details.bank_name
    setProfileData((prevData) => ({
      ...prevData,
      claimant_details: {
        ...prevData.claimant_details,
        personal_details: {
          ...prevData.claimant_details.personal_details,
          [field]: value,
        },
      },
    }));
  };
  const handleInputAccountChange = (field: string, value: string) => {
    console.log('first', field, value)
    //profileData.claimant_details.account_details.bank_name
    setProfileData((prevData) => ({
      ...prevData,
      claimant_details: {
        ...prevData.claimant_details,
        personal_details: {
          ...prevData.claimant_details.personal_details,
        },
        account_details: {
          ...prevData.claimant_details.account_details,
          [field]: value,
        },
      },
    }));
  };
  const fetchClaimant = async () => {
    const id_number = localStorage.getItem('idNumber');
    if (id_number)
      await getClaimant(id_number).then((response) => {
        console.log('response', response)
        setProfileData(response);
        if (response.claimant_details.personal_details.id_picture !== '') {
          setBase64Taz(response.claimant_details.personal_details.id_picture)
        }
        if (response.claimant_details.account_details.bank_account_picture !== '') {
          setbase64CheckClaimant(response.claimant_details.account_details.bank_account_picture)
        }

      });
  };
  const close = () => {
    modal.current?.dismiss();
    setMessSuccess(false);
  }
  const confirm = async () => {
    console.log('removed')
    //todo api to delete account and logout
    let removed: any = await deleteUser();
    console.log('removed', removed);
    if (removed) {

      setMessSuccess(true);
      const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL
        close()
        history.push('/logout');
        // window.location.replace(`${domain}/logout`);
      }, 3000);

      return () => clearTimeout(timeout);

    }
  }




  useEffect(() => {
    fetchClaimant();
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{languageData.profile}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <div className="main_container">
          <form onSubmit={handleSubmit}>
            <h3>{languageData.Personal_Details}</h3>
            <IonItem>
              <IonLabel >{languageData.First_Name}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.first_name}
                onIonChange={(e) => handleInputChange('first_name', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Last_Name}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.last_name}
                onIonChange={(e) => handleInputChange('last_name', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.ID_Number}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.id_number}
                onIonChange={(e) => handleInputChange('id_number', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.PhoneNumber}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.phone_number}
                onIonChange={(e) => handleInputChange('phone_number', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.email}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.email}
                onIonChange={(e) => handleInputChange('email', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.City}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.city}
                onIonChange={(e) => handleInputChange('city', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Street}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.street}
                onIonChange={(e) => handleInputChange('street', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.House_Number}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.house_number}
                onIonChange={(e) => handleInputChange('house_number', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.ZIP_Code}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.personal_details.zip}
                onIonChange={(e) => handleInputChange('zip', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.ID_Picture}:</IonLabel>
              {base64Taz && (
                <img alt="ID" src={`data:image/jpg;base64,${base64Taz}`} height="100" />
              )}

            </IonItem>
            <h3>{languageData.Account_Details}</h3>
            <IonItem>
              <IonLabel >{languageData.Bank_Name}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.account_details.bank_name}
                onIonChange={(e) => handleInputAccountChange('bank_name', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Bank_Number}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.account_details.bank_id}
                onIonChange={(e) => handleInputAccountChange('bank_id', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Branch_Name}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.account_details.branch_name}
                onIonChange={(e) => handleInputAccountChange('branch_name', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Branch_Number}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.account_details.branch}
                onIonChange={(e) => handleInputAccountChange('branch', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Account_Number}:</IonLabel>
              <IonInput
                type="text"
                value={profileData.claimant_details.account_details.account_number}
                onIonChange={(e) => handleInputAccountChange('account_number', e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel >{languageData.Check_Picture}:</IonLabel>
              {base64CheckClaimant && (
                <img alt="bank account" src={`data:image/jpg;base64,${base64CheckClaimant}`} height="100" />
              )}
            </IonItem>
            <div className='button_wrapper'>
              <IonButton type="submit">
                {languageData.save}
              </IonButton>
              <IonButton color="danger" type="button" id="open-modal">
                {languageData.Delete_Account}
              </IonButton>
            </div>
          </form>

          <IonToast isOpen={isOpen} onDidDismiss={() => { setSaveProfileError(false); setIsOpen(false) }} message={saveProfileMessage} duration={3000} position="top" icon={saveProfileError ? alertCircleOutline : checkmarkCircleOutline} color={saveProfileError ? "danger" : "success"} />


          <IonModal ref={modal} trigger="open-modal" >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => close()}> {languageData.Cancel}</IonButton>
                </IonButtons>
                <IonTitle>{languageData.Delete_Account}</IonTitle>
                <IonButtons slot="end">
                  <IonButton strong={true} onClick={() => confirm()}>
                    {languageData.Confirm}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

              <IonText> {languageData.deletetion_messsage} </IonText>

              {(messSuccess) && (
                <p className='success'>
                  {/* <IonIcon icon="./assets/img/icon/thumb.svg"/> */}
                  <strong>{languageData.Delete_Comfirmation}</strong>
                </p>
              )}
            </IonContent>
          </IonModal>

          <Loader isloading={loading} />

        </div>
      </IonContent>

    </IonPage>
  );

};

export default ProfilePage;
