import React, {  useEffect } from 'react';

const Logout: React.FC = () => {
    const logout = () =>{
      console.log('logout');
        // localStorage.removeItem('user');
        // localStorage.removeItem('p');
        // localStorage.removeItem('access_token');
        // localStorage.removeItem('expiration_token');
        localStorage.clear();
        window.location.href = "/";
    }
  useEffect(() => {
    console.log('logout');
    logout();
  }, [])
  
  return (
    <div>Logout</div>
    
  );
};

export default Logout;
