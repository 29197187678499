import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import ThankYou from './pages/ThankYou';
import Camera from './pages/Camera';
import Profile from './pages/Profile';

import Login from './pages/Login';
import DashboardPage from './pages/DashboardPage';
import ItemPage from './components/ItemPage';
import NewClaim from './pages/NewClaim';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import './pages/Page.css';
import './theme/scss/style.css';

import { useState } from 'react';
import Logout from './pages/Logout';
import ViewClaim from './pages/ViewClaim';


setupIonicReact();



const App: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  //TODO Implement your authentication check logic here
  // For example, check if the user is logged in by verifying the session or token
  let logged = localStorage.getItem('logged');
  if (!isLoggedIn && logged === 'true') {
    setLoggedIn(true)

  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {isLoggedIn && (
            <Menu />
          )}


          {isLoggedIn && (
            <IonRouterOutlet id="main">
              <Switch>
                <Route path="/" exact={true} render={() => <DashboardPage items={[]} />} />
                <Route path="/new-claim" exact={true}
                  component={() => <Route component={NewClaim} />}
                />

                <Route path="/new-claim/:step" component={NewClaim} />
                <Route
                  exact
                  path="/claim/:claim_number"
                  render={(props) => <ItemPage claimNumber={props.match.params.claim_number} />}
                />
                <Route
                  exact
                  path="/thank-you/:claimOption"
                  render={(props) => <ThankYou claimOption={props.match.params.claimOption} />}
                />
                <Route path="/profile" exact={true} ><Profile /></Route>
                <Route path="/logout" exact={true} ><Logout /></Route>

                <Route path="/page/:name" exact={true} />
              </Switch>
            </IonRouterOutlet>
          )}
          {!isLoggedIn && (
            <IonRouterOutlet id="main">
              <Route path="/"  >
                <Login />
                <Redirect from="*" to="/" />
              </Route>
              <Route path="/view/:claim_number" render={(props) => <ViewClaim claimNumber={props.match.params.claim_number} />} />
            </IonRouterOutlet>
          )}


        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
//localhost:8100/view/21554ba8-059e-44db-95d3-969298be6a64